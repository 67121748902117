/* eslint-disable */
import { makeStyles } from '@mui/styles';
import { Editor as RdwEditor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import BoldIcon from 'assets/wysiwygIcons/BoldIcon.svg';
import ItalicIcon from 'assets/wysiwygIcons/ItalicIcon.svg';
import UnderlineIcon from 'assets/wysiwygIcons/UnderlineIcon.svg';
import LeftAlignIcon from 'assets/wysiwygIcons/LeftAlignIcon.svg';
import CenterAlignIcon from 'assets/wysiwygIcons/CenterAlignIcon.svg';
import NumberListIcon from 'assets/wysiwygIcons/NumberListIcon.svg';
import UndoIcon from 'assets/wysiwygIcons/UndoIcon.svg';
import RedoIcon from 'assets/wysiwygIcons/RedoIcon.svg';
import LinkIcon from 'assets/LinkIcon.svg';
import InputLabel from './InputLabel';

export const Editor = ({
  editorState,
  setEditorState,
  size,
  height,
  error,
  label,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <InputLabel>{label}</InputLabel>
      <RdwEditor
        editorState={editorState}
        onEditorStateChange={setEditorState}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        toolbar={{
          options: ['inline', 'textAlign', 'list', 'link', 'history'],
          inline: {
            options: ['bold', 'italic', 'underline'],
            bold: { icon: BoldIcon },
            italic: { icon: ItalicIcon },
            underline: { icon: UnderlineIcon }
          },
          textAlign: {
            options: ['left', 'center'],
            left: { icon: LeftAlignIcon },
            center: { icon: CenterAlignIcon }
          },
          list: {
            inDropdown: false,
            options: ['ordered'],
            ordered: { icon: NumberListIcon }
          },
          link: {
            options: ['link'],
            link: { icon: LinkIcon }
          },
          history: {
            undo: { icon: UndoIcon },
            redo: { icon: RedoIcon }
          }
        }}
        {...rest}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    '& .wrapper-class': {
      border: ({ error }) =>
        `1px solid ${error ? '#F48989' : theme.palette.grey.innerBorder}`,
      borderRadius: 4,
      width: '100%'
    },

    '& .editor-class': {
      padding: '12px 24px',
      minHeight: 120,
      maxHeight: 180,

      '& .public-DraftStyleDefault-block': {
        marginTop: 6,
        marginBottom: 6
      }
    },

    '& .toolbar-class': {
      padding: 14,
      borderBottom: '1px solid #F2F2F2',
      borderRadius: '4px 4px 0px 0px',
      marginBottom: 0,
      border: 'none'
    },

    '& .rdw-inline-wrapper, & .rdw-text-align-wrapper, & .rdw-list-wrapper, & .rdw-history-wrapper, & .rdw-link-wrapper':
      {
        marginBottom: 0
      },

    '& .rdw-history-wrapper': {
      marginLeft: 'auto'
    },

    '& .rdw-text-align-wrapper': {
      marginLeft: '15%'
    },

    '& .rdw-list-wrapper': {
      '& .rdw-option-wrapper': {
        marginLeft: 15
      }
    },

    '& .rdw-link-wrapper': {
      marginLeft: '15%'
    },

    '& .rdw-option-wrapper': {
      border: 'none',
      '&:not(:last-of-type)': {
        marginRight: 15
      }
    },

    '& .history': {}
  }
}));
