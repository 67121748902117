/* eslint-disable */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Drawer, styled, Button, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm, Controller } from 'react-hook-form';

import { InputField, TextArea, Chip, LiveAutocomplete } from 'reusables';
import { passControllerProps } from 'utils/inputUtils';
import { ReactComponent as CancelIcon } from 'assets/ChipCancelIcon.svg';

const Form = styled('form')(() => ({
  width: 528,
  padding: 24,
  paddingTop: 11,

  '& .MuiInputLabel-root': {
    marginTop: 13
  },

  '& textArea': {
    resize: 'none',
    height: '112px !important'
  },

  '& .chips': {
    display: 'block',
    marginTop: 12,
    '& > *': {
      marginRight: 4
    }
  }
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  width: '100%',
  padding: '14px 24px',
  boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
  display: 'flex',
  justifyContent: 'space-between',
  position: 'relative',

  '& .MuiTypography-root': {
    color: theme.palette.text.primary,
    fontWeight: 600
  },

  '& svg': {
    width: 15,
    height: 15
  }
}));

const DrawerFooter = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  background: theme.palette.primary.lighterAlt,
  position: 'absolute',
  bottom: 0,
  padding: '16px 1px',

  '& .MuiButton-root': {
    padding: '6px 20px'
  }
}));

const renderFacilitators = (facilitators, onDelete) => {
  return facilitators?.map((facilitator) => {
    if (!facilitator) return <></>;

    const { id, image, firstName, lastName } = facilitator;
    const initials = firstName?.[0] + lastName?.[0];
    const name = `${firstName} ${lastName}`;
    const icon = image || <div>{initials?.toUpperCase()}</div>;

    return (
      <Chip
        key={id || firstName}
        label={name}
        avatar={icon}
        size="small"
        onDelete={() => onDelete(id)}
      />
    );
  });
};

const LearningTrackForm = ({ open, onClose, defaultValues, onSubmit, isLoading }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    watch,
    reset
  } = useForm({ defaultValues });

  const formValues = watch();

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const handleFacilitatorSelect = (option) => {
    if (!option) {
      trigger('facilitators');
      return;
    }
    const isExisting = formValues.facilitators.find((f) => f.id === option.id);
    if (isExisting) return;
    setValue('facilitators', [...formValues.facilitators, option]);
    trigger('facilitators');
  };

  const handleRemoveFacilitator = (facilitatorId) => {
    const newFacilitators = formValues.facilitators.filter((f) => f.id !== facilitatorId);
    setValue('facilitators', newFacilitators);
    trigger('facilitators');
  };

  const handleClose = () => {
    if (isLoading) return;
    onClose();
    reset(defaultValues);
  };

  return (
    <Drawer anchor="right" open={open} onClose={handleClose}>
      <DrawerHeader>
        <Typography>Add Learning Track</Typography>

        <IconButton onClick={handleClose} disableRipple>
          <CancelIcon />
        </IconButton>
      </DrawerHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="title"
          rules={{ required: 'This field is required' }}
          render={(props) => (
            <InputField
              label="Track Title"
              fullWidth
              error={errors.title}
              helperText={errors.title?.message}
              {...passControllerProps(props)}
            />
          )}
        />

        <Controller
          control={control}
          name="description"
          rules={{ required: 'This field is required' }}
          render={(props) => (
            <TextArea
              label="Track's Description"
              minRows={4}
              fullWidth
              {...passControllerProps(props)}
              error={errors.description}
              helperText={errors.description?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="facilitators"
          render={() => (
            <LiveAutocomplete
              label="Facilitators"
              header="Result"
              onOptionSelect={handleFacilitatorSelect}
              noOptionsNode="No result founded"
              clearOnSelect
              url="/users"
              queryParams={{ searchBy: 'Facilitator' }}
              getOptionLabel={(d) => `${d?.firstName} ${d?.lastName}`}
              error={errors.facilitators}
              helperText={errors.facilitators?.message}
            />
          )}
        />
        <span className="chips">
          {renderFacilitators(formValues.facilitators, handleRemoveFacilitator)}
        </span>
      </Form>

      <DrawerFooter>
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading}
          loading={isLoading}
        >
          Save Track
        </LoadingButton>
        <Button color="primary" variant="text" onClick={handleClose} disabled={isLoading}>
          Cancel
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};

export default LearningTrackForm;

LearningTrackForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  defaultValues: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    facilitators: PropTypes.arrayOf({
      id: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string
    })
  })
};

LearningTrackForm.defaultProps = {
  defaultValues: {
    title: '',
    description: '',
    facilitators: []
  }
};
