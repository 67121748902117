import {
  Breadcrumbs as MuiBreadcrumbs,
  styled,
  Link,
  Typography,
  SvgIcon
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { ReactComponent as SeparatorIcon } from 'assets/chevronRight.svg';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

export const Breadcrumbs = ({ current, historyStack, ...rest }) => {
  return (
    <StyledBreadcrumbs
      aria-label="breadcrumb"
      separator={<SvgIcon component={SeparatorIcon} viewBox="0 0 7 12" />}
      {...rest}
    >
      {historyStack.map((stack) =>
        stack.to ? (
          <Link component={RouterLink} to={stack.to} underline="none" key={uuid()}>
            {stack.title}
          </Link>
        ) : (
          <Typography key={uuid()} onClick={stack.onClick} className="link">
            {stack.title}
          </Typography>
        )
      )}
      <Typography className="title">{current}</Typography>
    </StyledBreadcrumbs>
  );
};

Breadcrumbs.propTypes = {
  current: PropTypes.string.isRequired,
  historyStack: PropTypes.arrayOf(PropTypes.object)
};

Breadcrumbs.defaultProps = {
  historyStack: []
};

const StyledBreadcrumbs = styled(MuiBreadcrumbs)({
  '& .MuiBreadcrumbs-separator': {
    '& .MuiSvgIcon-root': {
      fontSize: 12
    }
  },

  '& .MuiBreadcrumbs-li': {
    '& .MuiLink-root': {
      fontSize: 18,
      fontWeight: 400,
      color: '#605E5C'
    },
    '& .MuiTypography-body1.link': {
      fontWeight: 400,
      fontSize: 18,
      color: '#605E5C',
      cursor: 'pointer'
    },
    '& .MuiTypography-body1.title': {
      fontWeight: 600,
      fontSize: 18,
      color: '#201F1E'
    }
  }
});
