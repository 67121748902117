export default function Collapse() {
  return {
    MuiCollapse: {
      styleOverrides: {
        root: {
          height: '100%'
        },
        wrapper: {
          height: '100%'
        },
        wrapperInner: {
          height: '100%'
        }
      }
    }
  };
}
