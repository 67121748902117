import { Popover as MuiPopover, MenuItem, styled } from '@mui/material';
import PropTypes from 'prop-types';

import { pxToRem } from 'utils/formatFont';

const Wrapper = styled(MuiPopover)(({ theme }) => ({
  '& .MuiPopover-paper': {
    borderRadius: 0,
    marginLeft: -120,
    marginRight: 120,
    width: 124
  },

  '& .MuiMenuItem-root': {
    fontSize: pxToRem(14),
    fontWeight: 600,
    color: theme.palette.text.tertiary
  }
}));

const Popover = ({ id, open, anchorEl, onClose, menuOptions, anchorOrigin }) => {
  return (
    <Wrapper
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
    >
      {menuOptions.map((option) => (
        <MenuItem key={option.label} onClick={option.onClick}>
          {option.label}
        </MenuItem>
      ))}
    </Wrapper>
  );
};

export default Popover;

Popover.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired
    })
  ).isRequired,
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string
  })
};

Popover.defaultProps = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'left'
  }
};
