import { Chip as MuiChip, Avatar } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

import { ReactComponent as CancelIcon } from 'assets/ChipCancelIcon.svg';

const Chip = ({ label, avatar, ...rest }) => {
  // eslint-disable-next-line
  const classes = useStyles();
  return (
    <MuiChip
      className={classes.root}
      label={label}
      avatar={avatar ? <Avatar>{avatar}</Avatar> : null}
      deleteIcon={<CancelIcon />}
      {...rest}
    />
  );
};

Chip.propTypes = {
  label: PropTypes.string.isRequired,
  avatar: PropTypes.node
};

Chip.defaultProps = {
  avatar: null
};

export default Chip;

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.grey.light,

    '& .MuiChip-deleteIcon': {
      marginLeft: 8,
      marginRight: 8
    }
  }
}));
