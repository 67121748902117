import Api from 'utils/Api';
import Auth from 'utils/Auth';
import handleApiError from 'utils/handleApiError';

export const submitCallForApplicationForm = async (formPayload, formId) => {
  const response = Api.post(`/activities/dynamic-form/${formId}`, formPayload);
  return response;
};

export const createUser = async (payload) => {
  try {
    const { data } = await Api.post(`/users/register`, payload);
    return data;
  } catch (error) {
    throw Error(handleApiError(error));
  }
};

export const getAuditLog = async (userId) => {
  try {
    const { data } = await Api.get(`/users/${userId}/activities`);
    return data;
  } catch (error) {
    throw Error(handleApiError(error));
  }
};

export const editProfile = async (payload) => {
  const userId = Auth.getUserId();
  try {
    const { data } = await Api.put(`/users/${userId}`, payload);
    return data;
  } catch (error) {
    throw Error(handleApiError(error));
  }
};

export const uploadProfileDocuments = async (payload) => {
  const userId = Auth.getUserId();
  try {
    const { data } = await Api.post(`/users/${userId}/documents`, payload);
    return data;
  } catch (error) {
    throw Error(handleApiError(error));
  }
};

export const getProfileDocuments = async () => {
  const userId = Auth.getUserId();
  try {
    const { data } = await Api.get(`/users/${userId}/documents`);
    return data.data;
  } catch (error) {
    throw Error(handleApiError(error));
  }
};

export const deleteProfileDocument = async (id) => {
  try {
    const { data } = await Api.delete(`/users/documents/${id}`);
    return data;
  } catch (error) {
    throw Error(handleApiError(error));
  }
};

export const getProfileDetails = async () => {
  const userId = Auth.getUserId();
  try {
    const { data } = await Api.get(`/users/${userId}`);
    return data.data;
  } catch (error) {
    throw Error(handleApiError(error));
  }
};

export const uploadApplicantFile = async (payload) => {
  try {
    const { data } = await Api.post(`/activities/applicants/file`, payload);
    return data;
  } catch (error) {
    throw Error(handleApiError(error));
  }
};

export const deleteApplicantFile = async (payload) => {
  try {
    const { data } = await Api.delete(`/activities/applicants/file`, { params: payload });
    return data;
  } catch (error) {
    throw Error(handleApiError(error));
  }
};

export const activateProgramManagers = async ({ id }) => {
  try {
    const { data } = await Api.put(`/users/${id}/activate`);
    return data;
  } catch (error) {
    throw Error(handleApiError(error));
  }
};

export const deactivateProgramManagers = async ({ id }) => {
  try {
    const { data } = await Api.put(`/users/${id}/suspend`);
    return data;
  } catch (error) {
    throw Error(handleApiError(error));
  }
};
