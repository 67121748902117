import { useReducer, useEffect } from 'react';
import { Autocomplete, FormHelperText } from '@mui/material';
import { validations } from '../helper.formRenderer';
import InputField from '../../InputField';
import InputLabel from '../../InputLabel';

const SelectField = ({ field, onFill, triggerValidation, existingValue }) => {
  const validator = validations[field.type];
  const options = field.options.map((option) => ({
    ...option,
    label: option.key
  }));

  const initialState = {
    value:
      existingValue && typeof options[0] !== 'string'
        ? options.find((option) => option.value === existingValue).label
        : '',
    errorMessage: ''
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'setValue':
        return { ...state, value: action.payload };

      case 'validate':
        return { ...state, errorMessage: validator(state.value, field) };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const { value, errorMessage } = state;

  useEffect(() => {
    if (triggerValidation) dispatch({ type: 'validate' });
  }, [triggerValidation]);

  const handleChange = (e, selected) => {
    dispatch({ type: 'setValue', payload: selected.label });
    dispatch({ type: 'validate' });
    onFill({ [field.key]: { value: selected.value, id: field.id } });
  };

  return (
    <>
      <InputLabel>{field.label}</InputLabel>
      <Autocomplete
        value={value}
        label={field.label}
        disablePortal
        autoComplete
        options={options}
        disableClearable
        fullWidth
        onChange={handleChange}
        renderInput={(params) => <InputField {...params} error={!!errorMessage} />}
      />
      <FormHelperText error={!!errorMessage}>{errorMessage}</FormHelperText>
    </>
  );
};

export default SelectField;
