import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Drawer as MuiDrawer, Box, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from './Footer';
import Header from './Header';

/**
 *
 * @component
 * Drawer displays a side drawer on the app.
 * It is built on Material Ui's Drawer component @https://mui.com/components/drawers/#main-content
 * Also accepts a list of tabs (https://mui.com/components/tabs/) as part of the header
 * @props details can be found under proptypes section
 *
 */
const Drawer = ({
  open,
  onClose,
  children,
  width,
  title,
  cancelText,
  cancelButtonProps,
  onOk,
  // eslint-disable-next-line no-unused-vars
  hasPadding = true,
  okText,
  okButtonProps,
  footer,
  tabList,
  ...rest
}) => {
  const classes = useStyles({ drawerWidth: width, hasPadding });
  const [currentTab, setCurrentTab] = useState(0);

  const renderTabPanel = (panel, index) => {
    return (
      <div
        role="tabpanel"
        hidden={currentTab !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
      >
        {currentTab === index && panel}
      </div>
    );
  };

  return (
    <MuiDrawer
      anchor="right"
      open={open}
      classes={{ root: classes.dialog }}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      {...rest}
    >
      <Box role="presentation" className={classes.container}>
        <Header
          title={title}
          currentTab={currentTab}
          onChangeTab={(value) => setCurrentTab(value)}
          tabList={tabList}
        />

        <div className={classes.body}>
          {tabList?.map((tab, index) => (
            <>{renderTabPanel(tab.panel, index)}</>
          ))}
          {children}
        </div>
        {footer !== undefined ? (
          footer
        ) : (
          <Footer
            okText={okText}
            okButtonProps={okButtonProps}
            onOk={onOk}
            cancelText={cancelText}
            cancelButtonProps={cancelButtonProps}
            onClose={onClose}
          />
        )}
      </Box>
    </MuiDrawer>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
    minWidth: 400,
    width: (props) => props.drawerWidth,
    [theme.breakpoints.down('xs')]: {
      width: '100vw !important'
    }
  },
  header: {
    padding: 13,
    width: '100%',
    height: 'auto',
    boxSizing: 'border-box',
    boxShadow: `inset 0px -1px 0px #E7E7ED`,
    '& .title': {
      margin: 0,
      wordWrap: 'break-word',
      fontSize: 14,
      fontWeight: 600
    }
  },
  body: {
    padding: (props) => (props.hasPadding ? '24px 16px' : 'none'),
    fontSize: 14,
    wordWrap: 'break-word',
    height: '100%',
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      padding: 20
    }
  }
}));

Drawer.propTypes = {
  ...MuiDrawer.propTypes,
  okText: PropTypes.node,
  onOk: PropTypes.func.isRequired, // Text on ok button
  okButtonProps: PropTypes.shape({
    ...Button.propTypes
  }).isRequired,
  cancelButtonProps: PropTypes.shape({
    ...Button.propTypes
  }).isRequired,
  cancelText: PropTypes.node, // Text on cancel button
  onClose: PropTypes.func.isRequired,
  title: PropTypes.node.isRequired,
  footer: PropTypes.node, // Default Footer can be overriden by passing the new footer element here
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tabList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired, // Tab Name
      panel: PropTypes.node.isRequired // Content under a Tab
    })
  )
};

Drawer.defaultProps = {
  cancelText: 'Cancel',
  okText: 'OK',
  footer: undefined,
  width: 240,
  tabList: null
};

export default React.memo(Drawer);
