import { useReducer, useEffect } from 'react';
import {
  FormControlLabel,
  FormGroup,
  FormLabel,
  Checkbox,
  FormHelperText
} from '@mui/material';
import { validations } from '../helper.formRenderer';

const MultipleAnswer = ({ field, onFill, triggerValidation, existingValue = '' }) => {
  const getInitialValues = () => {
    const existing = existingValue.split('; ');
    let options = {};
    field.options.forEach((option, index) => {
      options = { ...options, [option.key]: existing[index] || '' };
    });
    return options;
  };

  useEffect(() => {
    if (triggerValidation) dispatch({ type: 'validate' });
  }, [triggerValidation]);

  const combineFields = (f) =>
    Object.values(f)
      .filter((v) => v)
      .join('; ');

  const validator = validations[field.type];

  const initialState = {
    fields: getInitialValues(),
    errorMessage: ''
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'inputChange':
        return {
          ...state,
          fields: { ...state.fields, [action.payload.name]: action.payload.value }
        };

      case 'validate':
        return { ...state, errorMessage: validator(combineFields(state.fields), field) };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const onChange = (e, checked) => {
    const { name, value } = e.target;
    dispatch({ type: 'inputChange', payload: { name, value: checked ? value : false } });
    dispatch({ type: 'validate' });
    onFill({
      [field.key]: {
        id: field.id,
        value: combineFields(state.fields)
      }
    });
  };

  return (
    <>
      <FormGroup>
        <FormLabel error={!!state.errorMessage}>{field.label}</FormLabel>
        {field.options.map((option) => (
          <FormControlLabel
            key={option.id}
            control={
              <Checkbox
                name={option.key}
                value={option.value}
                checked={!!state.fields[option.key]}
                onChange={onChange}
              />
            }
            label={option.label || option.value || ''}
          />
        ))}
      </FormGroup>
      <FormHelperText error={!!state.errorMessage}>{state.errorMessage}</FormHelperText>
    </>
  );
};

export default MultipleAnswer;
