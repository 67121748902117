import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';

import useAuth from '../hooks/useAuth';
import { AuthPaths } from '../routes/paths';

AuthGuard.propTypes = {
  children: PropTypes.node
};

AuthGuard.defaultProps = {
  children: null
};

export default function AuthGuard({ children }) {
  const { isLoading, isAuthenticated } = useAuth();

  if (isLoading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ height: '100vh', w: 1 }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to={AuthPaths.SIGNIN} />;
  }

  return children;
}
