import LoadingImg from 'assets/loading.gif';
import { makeStyles } from '@mui/styles';

export const LoadingPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.loadingPage}>
      <img src={LoadingImg} alt="loading" />
    </div>
  );
};

const useStyles = makeStyles({
  loadingPage: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& img': {
      maxHeight: 300,
      maxWidth: 300
    }
  }
});
