import { Card, CardContent, SvgIcon, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReactComponent as TotalIcon } from 'assets/total-icon.svg';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const CountCard = ({
  title,
  value,
  IconComponent,
  radius,
  className,
  hasElevation,
  ...rest
}) => {
  const classes = useStyles({ hasElevation });

  return (
    <Card
      className={clsx(classes.cardRoot, className, { [classes.flatCard]: !hasElevation })}
      {...rest}
    >
      <CardContent>
        <div className="textContent">
          <Typography variant="h5" component="h5" className="title">
            {title}
          </Typography>
          <Typography className="value">{value}</Typography>
        </div>
        <SvgIcon component={IconComponent || TotalIcon} viewBox="0 0 32 32" />
      </CardContent>
    </Card>
  );
};

CountCard.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
  IconComponent: PropTypes.elementType,
  className: PropTypes.string,
  radius: PropTypes.number,
  hasElevation: PropTypes.bool // set hasElevation to false for your card to appear flat
};

CountCard.defaultProps = {
  className: '',
  title: 'Total',
  value: 0,
  IconComponent: TotalIcon,
  radius: 0,
  hasElevation: true
};

const useStyles = makeStyles({
  cardRoot: {
    border: '1px solid #EDEBE9',
    '& .MuiCardContent-root': {
      padding: 16,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'top',

      '&:last-child': {
        paddingBottom: 16
      },

      '& .textContent .title': {
        fontSize: 16,
        color: '#605E5C',
        fontWeight: 400
      },

      '& .textContent .value': {
        fontSize: 18,
        fontWeight: 600,
        color: '#323130'
      },

      '& .MuiSvgIcon-root': {
        fontSize: 32,
        color: 'transparent'
      }
    }
  },
  flatCard: {
    borderRadius: 4,
    boxShadow: 'none'
  }
});

export default CountCard;
