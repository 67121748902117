import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { makeStyles } from '@mui/styles';

const Footer = ({
  okText,
  onOk,
  cancelButtonProps,
  cancelText,
  onClose,
  okButtonProps,
  hasCancelButton = true
}) => {
  const classes = useStyles();

  return (
    <Paper elevation={0} className={classes.footer} square>
      <LoadingButton
        color="primary"
        variant="contained"
        disableElevation
        {...okButtonProps}
        onClick={onOk}
      >
        {okText}
      </LoadingButton>
      {hasCancelButton ? (
        <Button
          variant="outlined"
          className="cancelButton"
          {...cancelButtonProps}
          onClick={onClose}
        >
          {cancelText}
        </Button>
      ) : (
        <></>
      )}
    </Paper>
  );
};

const useStyles = makeStyles(() => ({
  footer: {
    background: '#EFF6FC',
    padding: 16,
    textAlign: 'right',
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 0,
    boxSizing: 'border-box',
    '& .cancelButton': {
      marginLeft: 8
    }
  }
}));

Footer.propTypes = {
  okText: PropTypes.node,
  onOk: PropTypes.func.isRequired,
  okButtonProps: PropTypes.shape({
    ...Button.propTypes
  }).isRequired,
  cancelButtonProps: PropTypes.shape({
    ...Button.propTypes
  }).isRequired,
  cancelText: PropTypes.node,
  onClose: PropTypes.func.isRequired
};

Footer.defaultProps = {
  okText: 'Ok',
  cancelText: 'Cancel'
};

export default React.memo(Footer);
