import { InputLabel as MuiInputLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { pxToRem } from 'utils/formatFont';

const InputLabel = ({ children, ...rest }) => {
  // eslint-disable-next-line
  const classes = useStyles();
  return (
    <MuiInputLabel className={classes.root} {...rest}>
      {children}
    </MuiInputLabel>
  );
};

export default InputLabel;

InputLabel.propTypes = {
  children: PropTypes.node.isRequired
};

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: pxToRem(14),
    fontWeight: 600,
    marginBottom: 5,
    color: theme.palette.primary.input,
    textTransform: 'capitalize',

    '& span': {
      textTransform: 'lowercase'
    }
  }
}));
