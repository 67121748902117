import { useReducer, useEffect } from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { validations } from '../helper.formRenderer';
import InputField from '../../InputField';

const DateComponent = ({ field, onFill, triggerValidation, existingValue = null }) => {
  const validator = validations[field.type];

  const initialState = {
    open: false,
    value: existingValue,
    errorMessage: ''
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'setValue':
        return { ...state, value: action.payload };
      case 'toggleOpen':
        return { ...state, open: !state.open };
      case 'setError':
        return { ...state, errorMessage: action.payload(state.value, field) };
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const { open, value, errorMessage } = state;

  useEffect(() => {
    if (triggerValidation && field.required) {
      dispatch({
        type: 'setError',
        payload: validator
      });
    }
  }, [triggerValidation, field, validator]);

  const onChange = (newValue) => {
    dispatch({ type: 'setValue', payload: newValue });
    dispatch({ type: 'setError', payload: validator });
    onFill({ [field.key]: { value: newValue, id: field.id } });
  };

  const sx = { '& input::placeholder': { opacity: 0 }, '& svg': { mr: 2 } };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        disableMaskedInput
        value={value}
        onClose={() => dispatch({ type: 'toggleOpen' })}
        placeholder="SOME"
        open={open}
        onChange={onChange}
        maxDate={
          field.key === 'DateOfBirth'
            ? new Date(
                `${
                  new Date().getUTCFullYear() - 18
                }-${new Date().getUTCMonth()}-${new Date().getUTCDate()}`
              )
            : null
        }
        renderInput={(params) => (
          <InputField
            sx={sx}
            fullWidth
            {...params}
            placeholder="Soe"
            onClick={() => dispatch({ type: 'toggleOpen' })}
            label={field.label}
            error={!!errorMessage}
            helperText={errorMessage}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DateComponent;
