import PropTypes from 'prop-types';
import { Modal, Button, Typography, IconButton, styled } from '@mui/material';

import { pxToRem } from 'utils/formatFont';
import { ReactComponent as BinIcon } from 'assets/Bin.svg';
import { ReactComponent as CloseIcon } from 'assets/Close.svg';

const Wrapper = styled('div')(({ theme }) => ({
  background: 'white',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 491,
  borderRadius: 8,
  boxSizing: 'border-box',
  overflow: 'hidden',
  padding: 0,

  '& .MuiIconButton-root': {
    position: 'absolute',
    right: 16,
    top: 34,
    '&:hover': {
      background: 'transparent'
    }
  },

  '& > .content': {
    margin: '48px 97px 0 97px',
    textAlign: 'center'
  },

  '& .MuiTypography-body1': {
    color: theme.palette.text.tertiary,
    fontWeight: 600,
    fontSize: pxToRem(18)
  },

  '& .MuiTypography-body2': {
    color: theme.palette.text.secondary,
    fontSize: pxToRem(14),
    fontWeight: 400
  },

  '& .btnGroup': {
    background: theme.palette.primary.lighterAlt,
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '16px 0',
    marginTop: 21,

    '& > .MuiButton-root': {
      padding: '6px 20px'
    }
  }
}));

const AlertDialog = ({
  icon,
  title,
  description,
  open,
  onOk,
  onCancel,
  cancelText,
  okText
}) => {
  return (
    <Modal open={open} onClose={onCancel}>
      <Wrapper>
        <IconButton onClick={onCancel}>
          <CloseIcon />
        </IconButton>
        <section className="content">
          {icon}
          <Typography variant="body1">{title}</Typography>
          <Typography variant="body2">{description}</Typography>
        </section>
        <section className="btnGroup">
          <Button color="primary" variant="contained" onClick={onCancel}>
            {cancelText}
          </Button>
          <Button onClick={onOk}>{okText}</Button>
        </section>
      </Wrapper>
    </Modal>
  );
};

AlertDialog.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  okText: PropTypes.string.isRequired,
  cancelText: PropTypes.string
};

AlertDialog.defaultProps = {
  icon: <BinIcon />,
  cancelText: 'Cancel'
};

export default AlertDialog;
