import React from 'react';
import TruncateMarkup from 'react-truncate-markup';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
/*
 * The reusable simply truncates strings (both html or non-html strings) depending on the number of lines specified and adds elipses to the end.
 */
const TruncateText = (props) => {
  const { lines, text, onTruncate } = props;

  function stripHtml(html) {
    const element = document.createElement('DIV');
    element.innerHTML = html;
    return element.textContent || element.innerText || '';
  }

  return (
    <span>
      <TruncateMarkup lines={lines} onTruncate={onTruncate}>
        <Typography sx={{ width: '100%' }} {...props}>
          {stripHtml(text)}
        </Typography>
      </TruncateMarkup>
    </span>
  );
};

TruncateText.propTypes = {
  ...Box.propTypes,
  lines: PropTypes.number,
  text: PropTypes.string,
  onTruncate: PropTypes.func
};

TruncateText.defaultProps = {
  lines: 2,
  text: '<p><p>',
  onTruncate: () => {}
};

export default TruncateText;
