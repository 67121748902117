import { useReducer, useEffect } from 'react';
import { Autocomplete, OutlinedInput, FormHelperText, Box } from '@mui/material';
import countries from 'data/countryCodes.json';
import { validations } from '../helper.formRenderer';
import InputField from '../../InputField';
import InputLabel from '../../InputLabel';

const PhoneNumber = ({ field, onFill, triggerValidation, existingValue = '' }) => {
  const validator = validations[field.type];
  const separatedValues = existingValue.split('-');
  const initialState = {
    extension: {
      name: '',
      dial_code: separatedValues?.[0] ?? '',
      code: ''
    },
    phoneNumber: separatedValues?.[1] || '',
    errorMessage: ''
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'setExtension':
        return { ...state, extension: action.payload };
      case 'setPhoneNumber':
        return { ...state, phoneNumber: action.payload };

      case 'validate':
        return {
          ...state,
          errorMessage: validator(state.extension && state.phoneNumber, field)
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const { extension, phoneNumber, errorMessage } = state;

  useEffect(() => {
    if (triggerValidation) dispatch({ type: 'validate' });
  }, [triggerValidation]);

  const handleExtensionChange = (e, selected) => {
    dispatch({
      type: 'setExtension',
      payload: selected
    });
  };
  const handleInputChange = (e) => {
    dispatch({ type: 'setPhoneNumber', payload: e.target.value });
    dispatch({ type: 'validate' });
  };

  const onBlur = () => {
    onFill({
      [field.key]: {
        value: `${extension.dial_code}-${phoneNumber}`,
        id: field.id
      }
    });
  };

  return (
    <>
      <InputLabel>{field.label}</InputLabel>
      <div style={{ display: 'flex' }}>
        <Autocomplete
          disablePortal
          autoComplete
          id="combo-box-demo"
          options={countries}
          getOptionLabel={(option) => `${option.dial_code} ${option.code}`}
          value={extension}
          disableClearable
          sx={{ width: 180 }}
          renderInput={(params) => (
            <InputField {...params} error={!!errorMessage && !extension} />
          )}
          onChange={handleExtensionChange}
          renderOption={(props, option) => (
            <Box
              key={option.code}
              component="li"
              sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt=""
              />
              {option.dial_code}
            </Box>
          )}
        />
        <OutlinedInput
          variant="outlined"
          sx={{ ml: 1, borderRadius: 0.5 }}
          fullWidth
          disabled={!extension}
          value={phoneNumber}
          onChange={handleInputChange}
          onBlur={onBlur}
          onInput={onBlur}
          error={!!errorMessage && !phoneNumber}
        />
      </div>
      <FormHelperText error={!!errorMessage}>{errorMessage}</FormHelperText>
    </>
  );
};

export default PhoneNumber;
