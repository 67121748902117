/* eslint-disable */
import { useState } from 'react';
import { IconButton, styled } from '@mui/material';
import { ReactComponent as DirectionArrows } from 'assets/DirectionArrows.svg';
import { ReactComponent as PencilWrite } from 'assets/PencilWrite.svg';
import { ReactComponent as Duplicate } from 'assets/Duplicate.svg';
import { fieldTypes } from './constants';
import {
  ShortAnswer,
  LongAnswer,
  SingleAnswer,
  MultipleAnswers,
  Rating,
  DateView,
  FileUpload,
  Placeholder
} from './Components.View';
import CustomFormEditMode from './CustomFormEditMode';

const components = {
  [fieldTypes.SHORT_ANSWER]: ShortAnswer,
  [fieldTypes.LONG_ANSWER]: LongAnswer,
  [fieldTypes.SINGLE_ANSWER]: SingleAnswer,
  [fieldTypes.MULTIPLE_ANSWER]: MultipleAnswers,
  [fieldTypes.RATING]: Rating,
  [fieldTypes.DATE]: DateView,
  [fieldTypes.FILE_UPLOAD]: FileUpload,
  [fieldTypes.SELECT]: Placeholder,
  [fieldTypes.LINKS]: Placeholder,
  [fieldTypes.PHONE_NUMBER]: ShortAnswer,
  [fieldTypes.EMAIL]: ShortAnswer
};

const CustomFormField = ({
  field,
  updateField,
  onDuplicate,
  onDelete,
  setCurrentDraggable
}) => {
  const [showControls, setShowControls] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const handleOnMouseEnter = () => {
    setShowControls(true);
  };
  const handleOnMouseLeave = () => {
    setShowControls(false);
  };

  const handleClose = () => {
    setShowControls(false);
    setEditMode(false);
  };

  if (editMode)
    return (
      <CustomFormEditMode
        onClose={handleClose}
        updateField={updateField}
        field={field}
        onDelete={onDelete}
      />
    );

  const Component = components[field.type];

  return (
    <Wrapper onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
      <div className="control">
        {showControls ? (
          <Control>
            <IconButton
              disableRipple
              onMouseEnter={() => {
                setCurrentDraggable(field.id);
              }}
              onMouseLeave={() => setCurrentDraggable('')}
            >
              <DirectionArrows />
            </IconButton>
            <IconButton disableRipple onClick={() => setEditMode(true)}>
              <PencilWrite />
            </IconButton>
            <IconButton disableRipple onClick={() => onDuplicate(field)}>
              <Duplicate />
            </IconButton>
          </Control>
        ) : null}
      </div>
      <Component field={field} />
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  '& .MuiTextField-root .Mui-disabled': {
    background: 'white',
    opacity: 1,
    '-webkit-text-fill-color': theme.palette.text.primary
  },
  '& .control': {
    height: 32
  },
  '& .MuiIconButton-root:hover': {
    background: 'transparent'
  },
  '& textarea': {
    resize: 'none'
  }
}));

const Control = styled('div')(({ theme }) => ({
  background: theme.palette.grey.light,
  borderRadius: '8px 8px 0 0',
  width: 96
}));

export default CustomFormField;
