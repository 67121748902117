import jwt_decode from 'jwt-decode';

const setToken = (token) => {
  localStorage.setItem('token', token);
};

const getToken = () => {
  return localStorage.getItem('token');
};

const getDecodedJwt = () => {
  try {
    const token = getToken();
    return jwt_decode(token);
  } catch (e) {
    return {};
  }
};

const removeToken = () => {
  localStorage.removeItem('token');
};

const logOut = () => {
  removeToken();
  window.location.replace('/');
};

const isAuthenticated = () => {
  try {
    const decodedToken = getDecodedJwt();

    const { exp } = decodedToken;
    const currentTime = Date.now() / 1000;

    return exp > currentTime;
  } catch (e) {
    return false;
  }
};

// This might not be useful if the user role is not encoded with the jwt_token
const isAdmin = () => {
  try {
    const decodedToken = getDecodedJwt();
    const { role } = decodedToken;
    return role.includes('Admin');
  } catch (error) {
    return false;
  }
};

const getUserRoles = () => {
  try {
    const decodedToken = getDecodedJwt();
    const { role } = decodedToken;
    return role;
  } catch (error) {
    return [];
  }
};

const getUserId = () => {
  try {
    const decodedToken = getDecodedJwt();
    const { UserId } = decodedToken;
    return UserId;
  } catch (error) {
    return undefined;
  }
};

const Auth = {
  isAuthenticated,
  getDecodedJwt,
  setToken,
  getToken,
  removeToken,
  logOut,
  isAdmin,
  getUserRoles,
  getUserId
};

export default Auth;
