/* eslint-disable */
import { useState, useEffect } from 'react';
import { styled, Box } from '@mui/material';
import { v4 as uuid } from 'uuid';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CustomFormField from './CustomFormField';
import CustomFormOptions from './CustomFormOptions';
import { FormActionCard } from 'modules/ProgramMutation/components';

const FormBuilder = ({ onCreateForm, onCreateFormFeedback, formFields, onPreview }) => {
  const [fields, setFields] = useState([]);
  const [currentDraggable, setCurrentDraggable] = useState('');

  useEffect(() => {
    if (Array.isArray(formFields)) {
      setFields(formFields);
    }
  }, [formFields]);

  const handleAddField = (selected) => {
    setFields([...fields, selected]);
  };

  const updateField = (newValues, id) => {
    const newFields = fields.map((field) => {
      if (field.id === id) return { ...field, ...newValues };
      return field;
    });
    setFields(newFields);
  };

  const handleDuplicate = (field) => {
    const uniqueId = uuid();
    const parentIndex = fields.indexOf(field);
    const _fields = [...fields];
    const removed = _fields.splice(parentIndex + 1, _fields.length);
    setFields([..._fields, { ...field, id: uniqueId, key: uniqueId }, ...removed]);
  };

  const handleDelete = (id) => {
    setFields(fields.filter((f) => f.id !== id));
  };

  const handleCreateForm = () => {
    onCreateForm({ formFields: fields.map((field, index) => ({ ...field, index })) });
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;
    setFields(reorder(fields, result.source.index, result.destination.index));
  };

  return (
    <ContainerDiv>
      <div
        style={{
          width: '100%',
          height: '100%',
          overflowY: 'auto'
        }}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="customFormFields">
            {(provided) => (
              <Wrapper ref={provided.innerRef} {...provided.droppableProps}>
                <FormFields>
                  {fields.map((field, index) => (
                    <Draggable
                      key={field.id}
                      isDragDisabled={!(currentDraggable === field.id)}
                      index={index}
                      draggableId={field.id}
                    >
                      {(_provided) => (
                        <div
                          ref={_provided.innerRef}
                          {..._provided.draggableProps}
                          {..._provided.dragHandleProps}
                        >
                          <CustomFormField
                            field={field}
                            updateField={updateField}
                            onDuplicate={handleDuplicate}
                            onDelete={handleDelete}
                            setCurrentDraggable={setCurrentDraggable}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                </FormFields>
              </Wrapper>
            )}
          </Droppable>
        </DragDropContext>
        <Box
          sx={{
            '& > *': {
              marginLeft: '0 !important',
              maxWidth: '606px !important'
            }
          }}
        >
          <FormActionCard
            primaryBtnProps={{
              onClick: handleCreateForm,
              label: 'Submit',
              loading: onCreateFormFeedback.isLoading
            }}
            secondaryBtnProps={{
              label: 'Preview',
              onClick: onPreview
            }}
          />
        </Box>
      </div>
      <CustomFormOptions onAddField={handleAddField} />
    </ContainerDiv>
  );
};

export default FormBuilder;

const ContainerDiv = styled('div')({
  display: 'flex',
  height: '100%'
});

const Wrapper = styled('div')(({ theme }) => ({
  width: '100%',
  minHeight: 450
}));

const FormFields = styled('div')(({ theme }) => ({
  width: 606,
  maxWidth: 606,
  '& > *': {
    marginTop: 16
  },
  '& > :first-child': {
    marginTop: 0
  }
}));
