import { useState } from 'react';
import { AppBar, styled, SvgIcon, Typography, Badge, Avatar, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Auth from 'utils/Auth';

import { useNavigate } from 'react-router-dom';

import { ReactComponent as GridRounded } from 'assets/grid-rounded.svg';
import { ReactComponent as NotificationIcon } from 'assets/notification-icon.svg';
import { ReactComponent as SettingsIcon } from 'assets/setting-icon.svg';
import { SearchInput } from 'reusables';
import useAuth from 'hooks/useAuth';
import useAuthRole from 'hooks/useAuthRole';
// import { ReactComponent as Logout } from 'assets/sideBarIcon/logout.svg';

import { getNameInitials } from 'utils/userUtils';

export const Header = ({
  profileNav = '/profile',
  showSettings = true,
  hasShadow = false
}) => {
  const { user } = useAuth();
  const [searchText, setSearchText] = useState('');
  const navigate = useNavigate();
  const { isBeneficiary } = useAuthRole();
  const classes = useStyles();

  const handleLogout = () => {
    Auth.removeToken();
    navigate('/', { replace: true });
  };

  const renderActions = () => {
    return (
      <>
        <Badge className="notificationBadge">
          <SvgIcon component={NotificationIcon} viewBox="0 0 12 14" fontSize="small" />
        </Badge>
        {showSettings ? (
          <SvgIcon
            component={SettingsIcon}
            viewBox="0 0 16 16"
            fontSize="small"
            className="settingsIcon"
          />
        ) : (
          <></>
        )}

        <UserBadge
          overlap="circular"
          variant="dot"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          onClick={() => navigate(profileNav)}
          sx={{
            cursor: 'pointer'
          }}
        >
          <Avatar
            alt="Remy Sharp"
            src={user.imageUrl ?? ''}
            sx={{ width: 32, height: 32 }}
          >
            {getNameInitials(user?.firstName, user?.lastName)}
          </Avatar>
        </UserBadge>

        {isBeneficiary && (
          <div>
            <button type="button" onClick={handleLogout} className={classes.LogOutBar}>
              Logout
            </button>
          </div>
        )}
      </>
    );
  };

  return (
    <NavBar
      position="sticky"
      elevation={0}
      style={{ boxShadow: hasShadow ? '0 1px 2px 0 rgba(0,0,0,.3)' : undefined }}
    >
      <div className="logo">
        <SvgIcon component={GridRounded} viewBox="0 0 15 15" fontSize="small" />
        <Typography component="h1" variant="h1">
          Nakise
        </Typography>
      </div>

      <SearchInput value={searchText} onChange={(value) => setSearchText(value)} />

      <Actions direction="row" alignItems="center" spacing={5}>
        {renderActions()}
      </Actions>
    </NavBar>
  );
};

const useStyles = makeStyles((theme) => ({
  LogOutBar: {
    fontSize: 12,
    fontWeight: 400,
    cursor: 'pointer',
    padding: '8px 24px',
    background: `#1493f4`,
    color: 'white',
    borderRadius: 40,
    border: 1,
    '&:hover': {
      background: `${theme.palette.primary.main}`
    },

    '&:nth-last-child(2)': {
      marginTop: 'auto'
    },

    '& .MuiSvgIcon-root': {
      marginRight: 10,
      fontSize: 16,
      color: 'transparent',
      marginBottom: 0
    }
  }
}));

const NavBar = styled(AppBar)({
  gridArea: 'header',
  minHeight: 48,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingLeft: '1.5%',
  paddingRight: '1.5%',

  '& .logo': {
    display: 'flex',
    alignItems: 'center',

    '& .MuiTypography-h1': {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1.5,
      marginLeft: 24
    }
  }
});

const Actions = styled(Stack)({
  '& .notificationBadge .MuiBadge-badge': {
    backgroundColor: '#005A9E',
    fontSize: 12
  },

  '& .MuiSvgIcon-root': {
    fontSize: 18
  },

  '& .MuiSvgIcon-root.settingsIcon': {
    fontSize: 19
  },
  '& .MuiSvgIcon-root.logOutIcon': {
    fontSize: 19,
    color: 'white'
  }
});

const UserBadge = styled(Badge)(({ theme }) => ({
  '& .MuiAvatar-root': {
    backgroundColor: '#CCE4F6',
    fontSize: 16,
    color: '#004578'
  },

  '& .MuiBadge-badge': {
    backgroundColor: '#6BB700',
    color: '#6BB700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      border: '1px solid currentColor',
      content: '""'
    }
  }
}));
