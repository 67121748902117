import Auth from 'utils/Auth';

export default function useAuthRole() {
  const roles = Auth.getUserRoles();
  return {
    isAdmin: roles.includes('Admin'),
    isFacilitator: roles.includes('Facilitator'),
    isBeneficiary: roles.includes('Beneficiary'),
    isProgramManager: roles.includes('ProgramManager'),
    isMentor: roles.includes('Mentor'),
    isSponsor: roles.includes('Sponsor'),
    ispartner: roles.includes('Partner'),
    isVolunteer: roles.includes('Volunteer'),
    isAlumni: roles.includes('Alumni'),
    isJobPartner: roles.includes('JobPartner')
  };
}
