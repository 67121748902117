export const hasNumber = (myString) => {
  return /\d/.test(myString);
};

export const hasLowerCase = (str) => {
  return /[a-z]/.test(str);
};

export const hasUpperCase = (str) => {
  return /[A-Z]/.test(str);
};

export const hasSpecialCharacter = (str) => {
  return /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(str);
};

export const inputRules = {
  email: {
    required: 'Email is required',
    pattern: {
      value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      message: 'Provide a valid email'
    }
  },
  base: {
    required: 'Input is required'
  },
  password: {
    required: 'Password is required',
    minLength: {
      value: 8,
      message: 'Password should be minimum of 8 characters'
    },
    validate: {
      hasUpperCase: (value) => hasUpperCase(value) || 'Should have an upper-case',
      hasLowerCase: (value) => hasLowerCase(value) || 'Should have a lower-case',
      hasNumber: (value) => hasNumber(value) || 'Should contain number',
      hasSpecialCharacter: (value) =>
        hasSpecialCharacter(value) || 'Should contain special charaters'
    }
  }
};

export const passControllerProps = ({ field: { ref, ...rest } }) => ({
  ...rest,
  inputRef: ref
});

export const phoneValidation = {
  pattern: {
    value:
      /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm,
    message: 'Invalid phone number'
  }
};

export const emailValidation = {
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,

    message: 'Invalid email address'
  }
};
