import { styled, AvatarGroup as MuiAvatarGroup, Avatar } from '@mui/material';
import { pxToRem } from 'utils/formatFont';

const AvatarGroup = ({ max = 6, avatars = [], ...rest }) => {
  const backgroundColors = [
    '#27AE60',
    '#4F6BED',
    '#038387',
    '#958EE6',
    '#D13438',
    '#274DAE',
    '#EB5757',
    '#F2994A'
  ];
  return (
    <StyledGroup max={max} {...rest} spacing={0}>
      {avatars.map((avatar, avatarIndex) => (
        <Avatar
          sx={{
            backgroundColor: `${backgroundColors[avatarIndex % backgroundColors.length]}`
          }}
          size="small"
          key={avatar?.id || avatarIndex}
          src={avatar?.imageUrl}
          alt={avatar?.firstName || avatar?.lastName}
        >
          {avatar?.firstName[0]}
          {avatar?.lastName[0]}
        </Avatar>
      ))}
    </StyledGroup>
  );
};

export default AvatarGroup;

const StyledGroup = styled(MuiAvatarGroup)(() => ({
  '.MuiAvatar-root': {
    width: 24,
    height: 24,
    fontSize: pxToRem(10)
  }
}));
