import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Typography, CircularProgress, Card, Button, styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-hot-toast';

import { AddNewItemButton, AlertDialog } from 'reusables';
import { pxToRem } from 'utils/formatFont';
import {
  getLearningTracks,
  createLearningTrack,
  editLearningTrack,
  deleteLearningTrack
} from 'services/programService';
import LearningTrackCard from './LearningTrackCard';
import LearningTrackForm from './LearningTrackForm';

const LearningTrack = ({ next }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [formDefaultValues, setFormDefaultValues] = useState(undefined);
  const [deleteStatus, setDeleteStatus] = useState({
    openAlert: false,
    trackId: ''
  });

  const formOptions = window.localStorage.getItem('formOptions');
  const { programId } = JSON.parse(formOptions);

  const { data, isFetching } = useQuery(['getLearningTracks', programId], () =>
    getLearningTracks(programId)
  );

  const queryClient = useQueryClient();

  const createMutation = useMutation(createLearningTrack, {
    onSuccess(d) {
      toast.success(d?.message || 'Saved!');
      setOpenDrawer(false);
      queryClient.refetchQueries(['getLearningTracks', programId]);
      setOpenDrawer(false);
    },
    onError(error) {
      toast.error(error.message || 'An error occurred');
    }
  });

  const editMutation = useMutation(editLearningTrack, {
    onSuccess(d) {
      toast.success(d?.message || 'Updated!');
      setOpenDrawer(false);
      queryClient.refetchQueries(['getLearningTracks', programId]);
      setFormDefaultValues(undefined);
      setOpenDrawer(false);
    },
    onError(error) {
      toast.error(error.message);
    }
  });

  const deleteMutation = useMutation(deleteLearningTrack, {
    onSuccess(d) {
      toast.success(d?.message || 'Updated!');
      setOpenDrawer(false);
      queryClient.refetchQueries(['getLearningTracks', programId]);
      setFormDefaultValues(undefined);
      setOpenDrawer(false);
    },
    onError(error) {
      toast.error(error.message);
    }
  });

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
    setFormDefaultValues(undefined);
  };

  const onSubmit = (values) => {
    if (values.id) {
      // an existing record should have an id
      const payload = {
        ...values,
        facilitators: values.facilitators?.map((f) => f.id)
      };
      return editMutation.mutate(payload);
    }
    const payload = {
      ...values,
      facilitators: values.facilitators.map((f) => f.id),
      programId
    };
    return createMutation.mutate(payload);
  };

  const onEdit = (learningTrack) => () => {
    setFormDefaultValues(learningTrack);
    setOpenDrawer(true);
  };

  const onDuplicate = (learningTrack) => () => {
    const payload = {
      ...learningTrack,
      title: `${learningTrack.title} - COPY`,
      id: undefined
    };
    onSubmit(payload);
  };

  const initDelete = (trackId) => {
    setDeleteStatus({
      trackId,
      openAlert: true
    });
  };

  const cancelDelete = () => {
    setDeleteStatus({
      trackId: '',
      openAlert: false
    });
  };

  const handleDelete = () => {
    deleteMutation.mutate(deleteStatus.trackId);
    cancelDelete();
  };

  const showLoader =
    isFetching || (!openDrawer && createMutation.isLoading) || deleteMutation.isLoading;

  return (
    <>
      <Wrapper>
        <Typography variant="h2">Learning Track</Typography>
        <Typography variant="body1">Add Learning Track</Typography>

        <section>
          {showLoader ? (
            <CircularProgress />
          ) : (
            data?.data?.map((learningTrack) => (
              <LearningTrackCard
                key={learningTrack.id}
                learningTrack={learningTrack}
                onEdit={onEdit(learningTrack)}
                onDelete={() => initDelete(learningTrack?.id)}
                onDuplicate={onDuplicate(learningTrack)}
              />
            ))
          )}
        </section>

        <LearningTrackForm
          open={openDrawer}
          onClose={handleCloseDrawer}
          defaultValues={formDefaultValues}
          onSubmit={onSubmit}
          isLoading={createMutation.isLoading || editMutation.isLoading}
        />

        <AddNewItemButton
          label="Add New Learning Track"
          onClick={() => setOpenDrawer(true)}
          disabled={showLoader}
        />
        <Card className="actionBox">
          <Button variant="outlined" color="secondary">
            Continue Later
          </Button>
          <LoadingButton
            loading={createMutation.isLoading}
            color="primary"
            variant="contained"
            onClick={
              data?.data?.length
                ? next
                : () => {
                    toast.error('Create a learning track');
                  }
            }
            type="submit"
          >
            Next
          </LoadingButton>
        </Card>
      </Wrapper>
      <AlertDialog
        open={deleteStatus.openAlert}
        onCancel={cancelDelete}
        onOk={handleDelete}
        okText="Yes, delete"
        title="Are you sure you want to delete?"
        description="Description of the imapact of deleting this day. Talk about sessions"
      />
    </>
  );
};

export default LearningTrack;

const Wrapper = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 641,
  position: 'relative',
  paddingBottom: 110,
  paddingTop: 16,

  '& > section > *': {
    marginBottom: 16,
    textAlign: 'left'
  },

  '& .MuiTypography-root': {
    textAlign: 'center'
  },

  '& > .MuiTypography-h2': {
    fontSize: pxToRem(20),
    fontWeight: 600,
    textAlign: 'center',
    paddingTop: 4,
    lineHeight: '28px',

    '&+.MuiTypography-body1': {
      fontSize: pxToRem(14),
      lineHeight: '20px'
    }
  },

  '& > .MuiTypography-body1': {
    marginBottom: 32,
    fontSize: pxToRem(14),
    color: theme.palette.text.secondary
  },

  '& .MuiDrawer-root': {
    background: 'red',
    display: 'none'
  },

  '&  .MuiCircularProgress-root': {
    margin: 'auto',
    marginBottom: 16,
    display: 'block'
  },

  '& .actionBox': {
    position: 'fixed',
    bottom: 50,
    width: '100%',
    maxWidth: 600,
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 16,

    '& > * ': {
      marginLeft: '16px',
      borderRadius: '2px'
    },

    '& .MuiButton-root': {
      padding: '6px 20px'
    },
    '& .Mui-disabled': {
      background: theme.palette.grey.light,
      color: '#A19F9D'
    }
  }
}));
