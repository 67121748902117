import { Controller } from 'react-hook-form';
import LocalTimePicker from './TimePicker';

export const ControlTimePicker = ({
  control,
  name,
  label,
  rules = {},
  ...restTimeProps
}) => {
  return (
    <Controller
      control={control}
      rules={rules}
      name={name}
      render={({ field: { ref, ...rest } }) => (
        <LocalTimePicker inputRef={ref} label={label} {...restTimeProps} {...rest} />
      )}
    />
  );
};
