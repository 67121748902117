import AuthGuard from 'guards/AuthGuard';
import { Suspense, Fragment } from 'react';
import { Routes, BrowserRouter as Router, Route } from 'react-router-dom';
import { LoadingPage } from 'components/LoadingPage';
import { routes } from './routes';

const renderRoute = ({ component: Component, ...route }) => {
  const Guard = route.isAuthenticated ? AuthGuard : Fragment;
  const Layout = route.layout ? route.layout : Fragment;

  return (
    <Route
      key={route.path}
      path={route.path}
      element={
        <Layout>
          <Suspense fallback={<LoadingPage />}>
            <Guard>
              <Component />
            </Guard>
          </Suspense>
        </Layout>
      }
    />
  );
};

export const RoutesWrapper = () => {
  return (
    <Router>
      <Routes>{routes.map((route) => renderRoute(route))}</Routes>
    </Router>
  );
};
