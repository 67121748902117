import { useRef, useState, useEffect, useReducer } from 'react';
import { FormHelperText, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import toast from 'react-hot-toast';
import { Chip } from 'reusables';
import { useMutation } from 'react-query';
import { ReactComponent as UploadIcon } from 'assets/UploadBottom.svg';
import { deleteApplicantFile, uploadApplicantFile } from 'services/usersService';
import { validations } from '../helper.formRenderer';
import InputLabel from '../../InputLabel';

const fileReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_FILE':
      return {
        files: [...state.files, action.payload]
      };
    case 'DELETE_FILE':
      return {
        files: state.files.filter((f) => f.name !== action.payload.name)
      };
    default:
      return state;
  }
};

const FileUpload = ({ field, onFill, triggerValidation, setSubmitLoader }) => {
  const validator = validations[field.type];
  const [errorMessage, setErrorMessage] = useState('');
  const [state, dispatch] = useReducer(fileReducer, { files: [] });

  const { mutate: uploadFile, isLoading: uploadingFile } = useMutation(
    uploadApplicantFile,
    {
      onSuccess: (data) => {
        const file = {
          name: data.filename,
          url: data.fileUrl
        };
        dispatch({ type: 'ADD_FILE', payload: file });
      },
      onError: () => {
        toast.error("Couldn't upload file please try again");
      },
      onSettled: () => {
        setSubmitLoader(false);
      }
    }
  );

  const { mutate: deleteFile, isLoading: deletingFile } = useMutation(
    deleteApplicantFile,
    {
      onSettled: () => {
        setSubmitLoader(false);
      }
    }
  );

  const inputRef = useRef(null);

  const handleClick = () => {
    inputRef?.current?.click();
  };

  useEffect(() => {
    if (triggerValidation && !state.files.length) setErrorMessage('Select a file');
  }, [triggerValidation, state, setErrorMessage]);

  useEffect(() => {
    onFill({
      [field.key]: { value: JSON.stringify(state.files), key: field.label, id: field.id }
    });
    // eslint-disable-next-line
  }, [state]);

  const handleChange = (e) => {
    const validationMessage = validator(e.target.files, field);
    setErrorMessage(validationMessage);
    if (validationMessage) return;

    for (let i = 0; i < field.file.numberLimit; i += 1) {
      setSubmitLoader(true);
      const formData = new FormData();
      formData.append('file', e.target.files[i]);
      uploadFile(formData);
    }
  };

  const handleRemoveFile = (name) => () => {
    const fileToDelete = state.files.find((f) => f.name === name);
    setSubmitLoader(true);
    deleteFile({ imageUrl: `${fileToDelete.url}` });
    dispatch({ type: 'DELETE_FILE', payload: fileToDelete });
  };

  return (
    <>
      <InputLabel error={!!errorMessage}>{field.label}</InputLabel>
      <input
        ref={inputRef}
        style={{ display: 'none' }}
        type="file"
        maxLength={field.file.numberLimit}
        multiple={field.file.numberLimit > 1}
        accept={field.file.type}
        onChange={handleChange}
      />
      <LoadingButton
        startIcon={<UploadIcon />}
        loading={uploadingFile || deletingFile}
        color="secondary"
        variant="outlined"
        sx={{ mt: 2 }}
        onClick={handleClick}
        disabled={state.files.length >= field.file.numberLimit}
      >
        {`Upload (${field.file.type.split('.')[1]})`}
      </LoadingButton>
      <Box>
        {state?.files?.map((file) => (
          <Chip
            sx={{ mr: 1, mt: 1 }}
            label={file?.name}
            key={file?.name}
            onDelete={handleRemoveFile(file?.name)}
          />
        ))}
        <FormHelperText error>{errorMessage}</FormHelperText>
      </Box>
    </>
  );
};

export default FileUpload;
