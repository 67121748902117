import { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { ProgramPaths } from 'routes/paths';
import { MenuList as MuiMenuList, MenuItem, ListItemIcon, styled } from '@mui/material';
import { pxToRem } from 'utils/formatFont';
import { AlertDialog } from 'reusables';
import { ReactComponent as BackArrow } from 'assets/tabIcons/back_arrow.svg';
import { ReactComponent as ActiveInfo } from 'assets/tabIcons/info_active.svg';
import { ReactComponent as InactiveInfo } from 'assets/tabIcons/info_inactive.svg';
import { ReactComponent as ActiveActivities } from 'assets/tabIcons/active_activities.svg';
import { ReactComponent as InactiveActivities } from 'assets/tabIcons/inactive_activities.svg';
import { ReactComponent as ActivePreview } from 'assets/tabIcons/active_preview.svg';
import { ReactComponent as InactivePreview } from 'assets/tabIcons/inactive_preview.svg';
import alertGIF from 'assets/gif/alert.gif';

const indexMapping = {
  0: ['basic'],
  1: ['track'],
  2: ['activities', 'CallForApplication', 'Events', 'Assessment'],
  3: ['preview']
};

export const FormTabs = ({ onTabClick, disableTabs }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [showCancelAlert, setShowCancelAlert] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { activityId } = useParams();

  const handleTabClick = (index) => {
    setActiveTabIndex(index);
    onTabClick(indexMapping[index][0]);
  };

  const handleBack = () => {
    setShowCancelAlert(false);
    navigate(ProgramPaths.PROGRAMS);
  };

  useEffect(() => {
    const pathnameArr = location.pathname.split('/').reverse();
    const currentPath = pathnameArr[0];
    const currentIndex = Object.keys(indexMapping).find((key) =>
      indexMapping[key].includes(currentPath)
    );
    setActiveTabIndex(parseInt(currentIndex, 10));
  }, [location, activityId]);

  const tabs = [
    {
      label: 'Basic Information',
      activeIcon: ActiveInfo,
      inactiveIcon: InactiveInfo
    },
    {
      label: 'Learning Track',
      activeIcon: ActiveInfo,
      inactiveIcon: InactiveInfo,
      disabled: disableTabs
    },
    {
      label: 'Activities',
      activeIcon: ActiveActivities,
      inactiveIcon: InactiveActivities,
      disabled: disableTabs
    },
    {
      label: 'Preview',
      activeIcon: ActivePreview,
      inactiveIcon: InactivePreview,
      disabled: disableTabs
    }
  ];

  return (
    <>
      <MenuList>
        <MenuItem
          disableRipple
          className="back-button"
          onClick={() => setShowCancelAlert(true)}
        >
          <ListItemIcon>
            <BackArrow />
          </ListItemIcon>
          Back
        </MenuItem>

        {tabs.map((tab, index) => {
          const isActive = activeTabIndex === index;
          const isDisabled = activeTabIndex < index || tab.disabled;
          const Icon = isActive ? tab.activeIcon : tab.inactiveIcon;
          return (
            <MenuItem
              key={tab.label}
              onClick={() => handleTabClick(index)}
              className={isActive ? 'active' : ''}
              disabled={isDisabled}
            >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              {tab.label}
            </MenuItem>
          );
        })}
      </MenuList>
      <AlertDialog
        icon={<img src={alertGIF} alt="alert" style={{ width: 74, margin: 'auto' }} />}
        title="You have unsaved data!"
        description="If you exit you will lose unsaved data and this action can not be undone."
        open={showCancelAlert}
        onOk={handleBack}
        onCancel={() => setShowCancelAlert(false)}
        okText="Yes, I want to exit"
        cancelText="Continue creation"
      />
    </>
  );
};

const MenuList = styled(MuiMenuList)(({ theme }) => ({
  width: 184,
  padding: 0,

  '& .MuiMenuItem-root': {
    fontSize: pxToRem(14),
    height: 36,
    background: 'white',
    marginBottom: 8,
    borderRadius: 8,
    '& .MuiListItemIcon-root': {
      marginRight: 8,
      padding: 0,
      minWidth: 10
    },

    '&:hover': {
      background: theme.palette.primary.lighterAlt
    },

    '&.back-button': {
      marginBottom: 24
    },

    '&.active': {
      color: theme.palette.primary.main,
      background: theme.palette.primary.lighterAlt
    }
  },

  '& .MuiListItemText-root': {
    marginLeft: 0
  }
}));

export default FormTabs;
