/* eslint-disable */
// This file provides an interface for all our api services defined in the services file of each module.
import axios from 'axios';
import Auth from './Auth';

export const base_url = process.env.REACT_APP_BASE_API_URL;

export const parseComplexQueryParams = (queryParams) => {
  const params = new URLSearchParams();
  Object.keys(queryParams).forEach((key) => {
    if (queryParams[key] instanceof Array) {
      queryParams[key].forEach((item) => {
        params.append(`${key}[]`, item);
      });
    } else {
      params.set(key, queryParams[key]);
    }
  });
  return params;
};

const Api = axios.create({
  baseURL: base_url
});

Api.interceptors.request.use(
  (config) => {
    // This adds an authorization key to config object if a token exists.
    if (Auth.isAuthenticated()) {
      config.headers.common['Authorization'] = `Bearer ${Auth.getToken()}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// This ensures that appropriate error message from the API are parsed to react query
Api.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || error)
);

export default Api;
