import { useQuery } from 'react-query';

import AuthApis from 'services/authApis';
import Auth from 'utils/Auth';

export default function useAuth() {
  const {
    data: { data } = {},
    isLoading,
    refetch
  } = useQuery({
    queryKey: 'userDetails',
    queryFn: AuthApis.getCurrentUser,
    config: {
      cacheTime: 3600 * 100,
      staleTime: 3600 * 100
    }
  });

  const userData = data?.data || {};

  return {
    user: userData,
    isLoading,
    isAuthenticated: Auth.isAuthenticated(),
    refetchUser: refetch
  };
}
