import { useState } from 'react';
import { AppBar, styled, SvgIcon, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AlertDialog } from 'reusables';
import { ReactComponent as NakiseLogo } from 'assets/nakise-textLogo.svg';
import alertGIF from 'assets/gif/alert.gif';

export const Header = () => {
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();

  const handleCancel = () => {
    setShowAlert(false);
    navigate('/programs');
  };

  return (
    <>
      <NavBar position="sticky" elevation={0}>
        <SvgIcon component={NakiseLogo} viewBox="0 0 93 24" />
        <Button onClick={() => setShowAlert(true)}>Cancel</Button>
      </NavBar>
      <AlertDialog
        icon={<img src={alertGIF} alt="alert" style={{ width: 74, margin: 'auto' }} />}
        title="You have unsaved data!"
        description="If you exit you will lose unsaved data and this action can not be undone."
        open={showAlert}
        onOk={handleCancel}
        onCancel={() => setShowAlert(false)}
        okText="Yes, I want to exit"
        cancelText="Continue creation"
      />
    </>
  );
};

const NavBar = styled(AppBar)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey.dashedBorder}`,
  backgroundColor: '#FFFFFF',
  gridArea: 'header',
  minHeight: 48,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingLeft: '1.5%',
  paddingRight: '1.5%',

  '& .MuiSvgIcon-root': {
    fontSize: 93,
    height: 'unset'
  },

  '& .MuiButton-root': {
    backgroundColor: '#F3F2F1',
    color: theme.palette.text.primary,
    fontWeight: 600
  }
}));
