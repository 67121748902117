import { useState } from 'react';
import { Paper, Typography, IconButton, Menu, MenuItem, SvgIcon } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReactComponent as CalenderIcon } from 'assets/calendar-icon.svg';
import { ReactComponent as MoreIcon } from 'assets/icons/moreIcon.svg';
import toast from 'react-hot-toast';
import { format, isAfter } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import LinesEllipsis from 'react-lines-ellipsis';
import classify from 'underscore.string/classify';

export const ActivityListItem = ({ activity, onDelete, onDuplicate }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const navigate = useNavigate();
  const classes = useStyles();

  const {
    id,
    title,
    description,
    startDate,
    endDate,
    activity: { name }
  } = activity;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const hasExpired = isAfter(new Date(), new Date(endDate));
  const toggleMenu = (e) => setAnchorEl(e.currentTarget);

  const handleMenuCLick = (e) => {
    if (hasExpired) {
      toast.error(`Activity has expired`);
    }
    toggleMenu(e);
  };

  const menuOptions = [
    {
      label: 'Edit',
      action: () => {
        navigate(`${id}/${classify(name)}`, {
          replace: true,
          state: {
            activeTab: 0
          }
        });
      },
      active: hasExpired
    },
    {
      label: 'Duplicate',
      action: () => onDuplicate(),
      active: hasExpired
    },
    {
      label: 'Delete',
      action: () => onDelete()
    }
  ];

  return (
    <Paper elevation={0} className={classes.paperRoot}>
      <IconButton onClick={handleMenuCLick} className={classes.moreBtn}>
        <SvgIcon component={MoreIcon} viewBox="0 0 24 24" />
      </IconButton>
      <Typography className="name">{name}</Typography>
      <Typography component="h2" className="title">
        {title}
      </Typography>
      <LinesEllipsis
        className="description"
        text={description}
        maxLine="2"
        ellipsis="..."
        trimRight
        basedOn="letters"
      />
      <div className="date">
        <SvgIcon component={CalenderIcon} viewBox="0 0 16 16" />
        <Typography>
          <span>{format(new Date(startDate), 'MMM dd, yyyy')}</span> -{' '}
          <span>{format(new Date(endDate), 'MMM dd, yyyy')}</span>
        </Typography>
      </div>
      <Menu
        id="activity-card menu"
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        open={openMenu}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        PopoverClasses={{
          root: classes.menuContent
        }}
      >
        {menuOptions.map(({ label, action, active }) => (
          <MenuItem disabled={active} key={label} onClick={action}>
            {label}
          </MenuItem>
        ))}
      </Menu>
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    marginTop: 16,
    padding: 16,
    position: 'relative',
    borderRadius: 0,
    minHeight: 160,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',

    '& .name': {
      color: theme.palette.primary.main,
      fontSize: 12,
      marginBottom: 4
    },

    '& .title': {
      fontSize: 14,
      fontWeight: 600,
      textTransform: 'capitalize',
      marginBottom: 8
    },

    '& .description': {
      width: '80%',
      fontSize: 14,
      fontWeight: 400,
      marginBottom: 16,
      color: theme.palette.text.secondary
    },

    '& .date': {
      width: 'fit-content',
      padding: '4px 8px',
      marginTop: 'auto',
      display: 'flex',
      alignItems: 'center',
      background: theme.palette.primary.lighterAlt,
      borderRadius: 4,

      '& .MuiSvgIcon-root': {
        fontSize: 16,
        color: 'transparent',
        stroke: theme.palette.primary.main
      },

      '& .MuiTypography-body1': {
        fontSize: 12,
        fontWeight: 400,
        color: theme.palette.primary.main,
        marginLeft: 8
      }
    }
  },

  moreBtn: {
    padding: 0,
    position: 'absolute',
    right: 10,
    top: 16,

    '&:hover': {
      background: 'transparent'
    }
  },

  menuContent: {
    '& .MuiPaper-root': {
      borderRadius: 0
    },

    '& .MuiList-root': {
      padding: '8px 0',

      '& .MuiMenuItem-root': {
        fontSize: 14,
        fontWeight: 400,
        cursor: 'pointer',
        color: theme.palette.text.tertiary
      }
    }
  }
}));
