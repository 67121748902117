// THIS IS THE DEFAULT VALUE YOU CAN CHANGE IF YOU WANT

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1140,
    xl: 1920
  }
};

export default breakpoints;
