import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  styled,
  Card as MuiCard,
  CardHeader,
  CardContent,
  Avatar,
  IconButton
} from '@mui/material';

import { Popover } from 'reusables';
import avatarColors from 'data/avatarColors';
import { ReactComponent as MoreVertIcon } from 'assets/MoreVertIcon.svg';
import { pxToRem } from 'utils/formatFont';

const Card = styled(MuiCard)(({ theme }) => ({
  marginTop: 16,
  textAlign: 'left',
  boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
  borderRadius: 1,
  padding: 16,
  minHeight: 108,

  '& .MuiCardHeader-root': {
    padding: 0,

    '& .MuiIconButton-root:hover': {
      background: 'transparent'
    }
  },
  '& .MuiCardHeader-title': {
    textAlign: 'left',
    color: theme.palette.text.primary,
    fontSize: pxToRem(14),
    fontWeight: 600
  },
  '& .MuiCardHeader-subheader': {
    textAlign: 'left',
    color: theme.palette.text.secondary,
    fontSize: pxToRem(12)
  },
  '& .MuiCardContent-root': {
    padding: 0,
    paddingTop: 10,
    display: 'flex',
    margin: 0,
    height: 24,

    '& > *': {
      marginRight: 4,
      fontSize: pxToRem(10),
      fontWeight: 600,
      height: 24,
      width: 24
    }
  }
}));

const renderAvatars = (avatars) => {
  const avatarsToRender = avatars.length > 6 ? avatars.slice(0, 5) : avatars;
  const hiddenAvatars = avatars.length - avatarsToRender.length;

  const getAvatarColor = (index) =>
    index > avatarColors.length
      ? avatarColors[avatarColors.length % index]
      : avatarColors[index];

  return (
    <>
      {avatarsToRender.map((user, index) =>
        user.avatar ? (
          <Avatar key={user.id} alt={user.firstName} src={user.avatar} />
        ) : (
          <Avatar key={user.id} sx={{ background: getAvatarColor(index) }}>
            {user.firstName[0] + user.lastName[0]}
          </Avatar>
        )
      )}

      {hiddenAvatars > 0 ? (
        <Avatar sx={{ background: '#F3F2F1', color: 'text.primary', fontWeight: 400 }}>
          +{hiddenAvatars}
        </Avatar>
      ) : null}
    </>
  );
};

const LearningTrackCard = ({ learningTrack, onEdit, onDelete, onDuplicate }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const menuOptions = [
    {
      label: 'Edit',
      onClick: () => {
        handleClose();
        onEdit();
      }
    },
    {
      label: 'Duplicate',
      onClick: onDuplicate
    },
    {
      label: 'Delete',
      onClick: () => {
        handleClose();
        onDelete();
      }
    }
  ];

  return (
    <Card>
      <CardHeader
        action={
          <IconButton onClick={handleClick}>
            <MoreVertIcon aria-describedby={id} />
          </IconButton>
        }
        title={learningTrack.title}
        subheader={learningTrack.description}
      />

      <CardContent>{renderAvatars(learningTrack.facilitators)}</CardContent>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        menuOptions={menuOptions}
      />
    </Card>
  );
};

export default LearningTrackCard;

LearningTrackCard.propTypes = {
  learningTrack: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    facilitators: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        avatar: PropTypes.string
      })
    )
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired
};
