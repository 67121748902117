/* eslint-disable */
import { Button, IconButton, InputAdornment, styled } from '@mui/material';
import { ReactComponent as PencilIcon } from 'assets/PencilWrite.svg';
import { ReactComponent as TrashIcon } from 'assets/Trash.svg';
import { pxToRem } from 'utils/formatFont';
import { fieldTypes, fieldLabels, fieldConfigs } from './constants';
import {
  ShortAnswer,
  LongAnswer,
  Option,
  Rating,
  DateView,
  FileUpload
} from './Components.Edit';
import InputField from '../InputField';
import Select from '../Select';
import Checkbox from '../Checkbox';

const components = {
  [fieldTypes.SHORT_ANSWER]: ShortAnswer,
  [fieldTypes.LONG_ANSWER]: LongAnswer,
  [fieldTypes.SINGLE_ANSWER]: Option,
  [fieldTypes.MULTIPLE_ANSWER]: Option,
  [fieldTypes.RATING]: Rating,
  [fieldTypes.DATE]: DateView,
  [fieldTypes.PHONE_NUMBER]: ShortAnswer,
  [fieldTypes.EMAIL]: ShortAnswer,
  [fieldTypes.FILE_UPLOAD]: FileUpload
};

const CustomFormEditMode = ({ onClose, updateField, field, onDelete }) => {
  const handleInputTypeChange = (type) => {
    const config = fieldConfigs.find((c) => c.type === type);
    if (!config.options) delete field.options;

    const payload = {
      ...config,
      id: field.id,
      key: field.key,
      label: field.label,
      ...(field.options && config.options && { options: field.options })
    };
    updateField(payload, field.id);
  };

  const handleInputChange = (name) => (e) => {
    name === 'required'
      ? updateField({ required: e.target.checked }, field.id)
      : updateField({ [name]: e.target.value }, field.id);
  };

  const Component = components[field.type];

  return (
    <Wrapper>
      <section className="header">
        <div>
          <Button startIcon={<PencilIcon />} color="secondary">
            Edit
          </Button>
        </div>
        <div>
          <InputField
            className="small-input"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <span>Key</span>
                </InputAdornment>
              )
            }}
            value={field.key}
            onChange={handleInputChange('key')}
          />
          <IconButton disableRipple onClick={() => onDelete(field.id)}>
            <TrashIcon />
          </IconButton>
        </div>
      </section>
      <section>
        <div className="input-changer-div">
          <InputField value={field.label} onChange={handleInputChange('label')} />
          <Select
            fullWidth
            className="small-input"
            value={field.type}
            onChange={(e) => handleInputTypeChange(e.target.value)}
            options={Object.entries(fieldLabels).map(([value, name]) => ({
              name,
              value
            }))}
          />
        </div>
        <Component field={field} updateField={updateField} />
        {[fieldTypes.SHORT_ANSWER, fieldTypes.LONG_ANSWER].includes(field.type) && (
          <InputField
            fullWidth
            label="Placeholder text"
            value={field.placeholder}
            onChange={handleInputChange('placeholder')}
          />
        )}
        <Checkbox
          label="Required"
          sx={{ pt: 0.5 }}
          checked={field.required}
          onChange={handleInputChange('required')}
        />
        <div className="btn-div">
          <Button onClick={onClose} variant="outlined" color="secondary">
            Done
          </Button>
        </div>
      </section>
    </Wrapper>
  );
};

export default CustomFormEditMode;

const Wrapper = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 606,
  background: '#F9FAFB',
  padding: 8,
  paddingBottom: 14,
  boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',

  '& .MuiInputBase-root': {
    background: 'white',
    '& .Mui-disabled': {
      background: 'white'
    }
  },

  '& textarea': {
    resize: 'none',
    background: 'white'
  },

  '& .MuiButton-root': {
    color: theme.palette.text.tertiary,
    '&:hover': {
      background: 'transparent'
    }
  },

  '& .header': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .small-input': {
      width: 219,

      '& .MuiInputBase-root input': {
        color: theme.palette.text.secondary
      }
    },

    '& .MuiIconButton-root': {
      paddingRight: 0,
      '&:hover': {
        background: 'transparent'
      }
    }
  },

  '& .small-input .MuiInputBase-root input': {
    padding: 6.5
  },
  '& .MuiInputAdornment-root': {
    background: '#F3F2F1',
    textAlign: 'center',
    height: 31,
    maxHeight: 31,
    margin: 0,
    width: 60,
    '& span': {
      margin: 'auto',
      display: 'block',
      color: theme.palette.text.secondary,
      fontSize: pxToRem(14)
    }
  },

  '& .input-changer-div': {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 15,
    marginBottom: 8,

    '& > :first-child': {
      width: 418,
      maxWidth: '69%'
    },
    '& .small-input': {
      marginTop: -2
    },
    '& .small-input .MuiSelect-select': {
      width: 158 - 12,
      padding: 6
    }
  },

  '& .MuiFormControlLabel-label': {
    fontWeight: 400
    // marginLeft: -5
  },
  '& .btn-div': {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '4px',

    '& .MuiButton-root': {
      fontWeight: 600,
      borderColor: theme.palette.grey.innerBorder,
      padding: '6px 20px'
    }
  }
}));
