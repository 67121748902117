/* eslint-disable */
import {
  Drawer,
  IconButton,
  styled,
  Typography,
  OutlinedInput,
  InputAdornment,
  SvgIcon
} from '@mui/material';
import clsx from 'clsx';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as SearchIcon } from 'assets/search-icon.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron_right.svg';
import { pxToRem } from 'utils/formatFont';

export const ActivitiesDrawer = ({ activityOptions, open, onClose }) => {
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <DrawerHeader>
        <div className="title">
          <Typography>Add Activity</Typography>
          <IconButton>
            <CloseIcon />
          </IconButton>
        </div>
        <OutlinedInput
          fullWidth
          placeholder="Search"
          sx={{ height: 34 }}
          startAdornment={
            <InputAdornment position="start">
              <SvgIcon
                component={SearchIcon}
                viewBox="0 0 17 16"
                fontSize="small"
                sx={{ fontSize: 16, padding: 0 }}
              />
            </InputAdornment>
          }
        />
      </DrawerHeader>

      <Body>
        {activityOptions.map(({ active, onClick, title, description }) => {
          return (
            <div
              className={clsx('card', { ['inActiveCard']: !active })}
              key={title}
              onClick={active ? onClick : () => {}}
            >
              <span className="description">
                <Typography variant="body1">{title}</Typography>
                <Typography variant="body2">{description}</Typography>
              </span>
              <ChevronRight />
            </div>
          );
        })}
      </Body>
    </Drawer>
  );
};

const DrawerHeader = styled('div')(({ theme }) => ({
  width: 340,
  padding: '5px 24px 16px 24px',
  boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',

  '& .title': {
    display: 'flex',
    width: '100%',
    marginBottom: '3px',
    justifyContent: 'space-between',
    '& > *': {
      display: 'block',
      margin: 'auto 0'
    },

    '& .MuiTypography-root': {
      color: theme.palette.text.primary,
      fontWeight: 600,
      fontSize: pxToRem(14)
    }
  }
}));

const Body = styled('div')(({ theme }) => ({
  width: 340,
  padding: 24,

  '& .card': {
    boxShadow: ' 0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 10px 12px 10px',
    marginBottom: 8,
    cursor: 'pointer',

    '& > *': {
      display: 'block',
      margin: 'auto 0'
    }
  },

  '& .inActiveCard': {
    color: '#b9b8b9',
    backgroundColor: '#e3e3e3',
    cursor: 'not-allowed !important'
  },

  '& .description': {
    width: 204,

    '& .MuiTypography-body1': {
      fontSize: pxToRem(14),
      fontWeight: 600,
      lineHeight: '20px',
      marginBottom: '2px'
    },

    '& .MuiTypography-body2': {
      fontSize: pxToRem(12),
      fontWeight: 400,
      lineHeight: '16px',
      color: theme.palette.text.secondary
    }
  }
}));
