import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Tabs, Tab } from '@mui/material';
import { makeStyles } from '@mui/styles';

const Header = ({ title, tabList, onChangeTab, currentTab }) => {
  const classes = useStyles();

  const a11yProps = (index) => {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`
    };
  };

  const renderTabList = () => {
    return (
      <Tabs
        value={currentTab}
        textColor="primary"
        indicatorColor="primary"
        onChange={(_evt, value) => onChangeTab(value)}
        aria-label="Modal tab"
        className={classes.tabs}
      >
        {tabList.map((tab, index) => (
          <Tab label={tab.label} {...a11yProps(index)} />
        ))}
      </Tabs>
    );
  };

  return (
    <div className={classes.header} style={{ paddingBottom: tabList && 0 }}>
      <Typography variant="body1" color="textPrimary" className="title">
        {title}
      </Typography>
      {tabList && renderTabList()}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  header: {
    padding: 16,
    width: '100%',
    boxSizing: 'border-box',
    boxShadow: `inset 0px -1px 0px #E7E7ED`,
    '& .title': {
      margin: 0,
      wordWrap: 'break-word',
      fontSize: 18,
      fontWeight: 600
    }
  },
  tabs: {
    paddingTop: 5,
    '& .MuiTab-root': {
      padding: 0,
      marginRight: 40,
      fontWeight: 400,
      fontSize: 14,
      color: '#201F1E'
    },
    '& .MuiTab-root.Mui-selected': {
      fontWeight: 600
    }
  }
}));

Header.propTypes = {
  title: PropTypes.string.isRequired,
  tabList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      panel: PropTypes.node.isRequired
    })
  ),
  currentTab: PropTypes.number,
  onChangeTab: PropTypes.func
};

Header.defaultProps = {
  tabList: null,
  currentTab: 0,
  onChangeTab: () => {}
};

export default React.memo(Header);
