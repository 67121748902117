import clsx from 'clsx';
import PropTypes from 'prop-types';
import { ButtonBase } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ReactComponent as AddIcon } from 'assets/addIcon.svg';

const AddNewItemButton = ({ label, ...rest }) => {
  const { className } = rest;

  // eslint-disable-next-line
  const classes = useStyles();
  return (
    <ButtonBase {...rest} className={clsx([className, classes.root])}>
      <AddIcon />
      <span>{label}</span>
    </ButtonBase>
  );
};

export default AddNewItemButton;

AddNewItemButton.propTypes = {
  label: PropTypes.string.isRequired
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: 104,
    width: '100%',
    display: 'block',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 400,
    backgroundColor: theme.palette.background.darkInput,
    border: `1px dashed ${theme.palette.grey.dashedBorder}`,

    '& > * ': {
      display: 'block',
      margin: 'auto'
    },

    '& >:first-child': {
      marginBottom: 8
    },

    '&:hover': {
      background: theme.palette.grey.light
    }
  }
}));
