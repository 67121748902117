const PRIMARY = {
  lighter: '#0078D4',
  light: '#137EE0',
  main: '#0078D4',
  dark: '#004AD7',
  darker: '#091A7A',
  input: '#201F1E',
  lighterAlt: '#EFF6FC',
  tertiary: '#2B88D8'
};
const SECONDARY = {
  lighter: '#393A4A',
  light: '#30313F',
  main: '#272833',
  dark: '#605E5C',
  darker: ''
};
const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#107C10',
  darker: ''
};
const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '',
  darker: ''
};
const WARNING = {
  lighter: '#FFF5EA',
  light: '#E0B878',
  main: '#B98900',
  dark: '',
  darker: ''
};
const ERROR = {
  lighter: '#FEEFEF',
  light: '#F48989',
  main: '#DA1414',
  dark: '#A4262C',
  darker: ''
};

const GREY = {
  disabled: '#E7E7ED',
  innerBorder: '#C8C6C4',
  light: '#F3F2F1',
  dashedBorder: '#EDEBE9',
  cardBg: '#FAF9F8',
  text: '#6B6C7E'
};

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY
  // divider: GREY[500_24]
};

const palette = {
  ...COMMON,
  text: {
    primary: '#201F1E',
    secondary: '#605E5C',
    disabled: '#A7A9BC',
    tertiary: '#323130'
  },
  background: {
    paper: '#fff',
    default: '#fff',
    neutral: GREY.disabled,
    primary: '#083A55',
    secondary: '#E5E5E5',
    lighter: '#DEECF9'
  }
};

export default palette;
