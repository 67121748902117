import clsx from 'clsx';
import { Select as MuiSelect, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

import { pxToRem } from 'utils/formatFont';
import { ReactComponent as ChevronDown } from 'assets/ChevronDown.svg';
import InputLabel from './InputLabel';

const Select = ({ label, options, selectLabel, ...rest }) => {
  const { className } = rest;
  // eslint-disable-next-line
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)}>
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        size="small"
        sx={{ p: 0, fontSize: pxToRem(14) }}
        IconComponent={ChevronDown}
        fullWidth
        {...rest}
      >
        {options.map((option) => (
          <MenuItem key={option.id || option.name} value={option.value}>
            {option.name}
          </MenuItem>
        ))}
      </MuiSelect>
    </div>
  );
};

Select.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired
};

Select.defaultProps = {
  label: ''
};

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiSelect-root': {
      borderRadius: 4
    },

    '& .MuiSelect-select': {
      padding: 10
    },

    '& .MuiInputBase-root': {
      border: 'none',
      borderColor: '#C8C6C4',
      fontWeight: 400,
      padding: 0,
      '& input': {
        padding: '10px 10px',
        fontSize: `${14 / 16}rem`
      }
    },

    '& .MuiSelect-icon': {
      marginTop: 3
    }
  }
}));
export default Select;
