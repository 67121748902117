import PropTypes from 'prop-types';
import { styled } from '@mui/material';
import { Header } from './Header';
import { SideBar } from './SideBar';

export const MainLayout = ({ children }) => {
  return (
    <Main>
      <Header />
      <aside className="appSideMenu">
        <SideBar />
      </aside>
      <article className="appContent">{children}</article>
    </Main>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired
};

const Main = styled('main')(() => ({
  display: 'grid',
  gridTemplateColumns: '230px auto',
  gridTemplateRows: '48px auto',
  gridTemplateAreas: `
    'header header'
    'sidebar content'`,
  height: '100vh',

  '& .appSideMenu': {
    gridArea: 'sidebar',
    width: '100%',
    height: '100%',
    backgroundColor: '#fff'
  },

  '& .appContent': {
    height: '100%',
    gridArea: 'content',
    width: '100%',
    overflowY: 'auto',
    padding: 32
  }
}));
