/* eslint-disable */
import { Box, CircularProgress } from '@mui/material';

const AppSpinner = ({ height }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{ height: `${!!height ? height : '100vh'}`, w: 1 }}
    >
      <CircularProgress />
    </Box>
  );
};

export default AppSpinner;
