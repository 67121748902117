import { v4 as uuid } from 'uuid';
import { ReactComponent as ShortAnswerIcon } from 'assets/formBuilder/ShortAnswer.svg';
import { ReactComponent as LongAnswerIcon } from 'assets/formBuilder/LongAnswer.svg';
import { ReactComponent as SingleAnswerIcon } from 'assets/formBuilder/SingleAnswer.svg';
import { ReactComponent as MultipleAnswerIcon } from 'assets/formBuilder/MultipleAnswer.svg';
import { ReactComponent as RatingIcon } from 'assets/formBuilder/Rating.svg';
import { ReactComponent as DateIcon } from 'assets/formBuilder/Date.svg';
import { ReactComponent as PhoneIcon } from 'assets/formBuilder/Phone.svg';
import { ReactComponent as EmailIcon } from 'assets/formBuilder/Email.svg';
import { ReactComponent as FileUploadIcon } from 'assets/formBuilder/FileUpload.svg';

// ===== DO NOT EDIT THESE TYPES ====
export const fieldTypes = Object.freeze({
  SHORT_ANSWER: 'ShortAnswer',
  LONG_ANSWER: 'LongAnswer',
  SINGLE_ANSWER: 'SingleAnswer',
  MULTIPLE_ANSWER: 'MultipleAnswer',
  RATING: 'Rating',
  DATE: 'Date',
  FILE_UPLOAD: 'FileUpload',
  SELECT: 'Select',
  LINKS: 'Links',
  PHONE_NUMBER: 'PhoneNumber',
  EMAIL: 'Email'
});

export const fieldLabels = {
  [fieldTypes.SHORT_ANSWER]: 'Short Answer',
  [fieldTypes.LONG_ANSWER]: 'Long Answer',
  [fieldTypes.SINGLE_ANSWER]: 'Single Answer',
  [fieldTypes.MULTIPLE_ANSWER]: 'Multiple Answer',
  [fieldTypes.RATING]: 'Rating',
  [fieldTypes.DATE]: 'Date',
  [fieldTypes.PHONE_NUMBER]: 'Phone Number',
  [fieldTypes.EMAIL]: 'Email',
  [fieldTypes.FILE_UPLOAD]: 'File Upload'
};
const options = [
  { key: 'option1', value: 'Option 1', label: 'Option 1' },
  { key: 'option2', value: 'Option 2', label: 'Option 2' },
  { key: 'option3', value: 'Option 3', label: 'Option 3' }
];

const createConfig = (type, specialConfig = {}) => ({
  type,
  id: uuid(),
  key: '',
  label: fieldLabels[type],
  required: true,
  ...specialConfig
});

export const fieldConfigs = [
  createConfig(fieldTypes.SHORT_ANSWER),
  createConfig(fieldTypes.LONG_ANSWER),
  createConfig(fieldTypes.SINGLE_ANSWER, { options }),
  createConfig(fieldTypes.MULTIPLE_ANSWER, { options }),
  createConfig(fieldTypes.RATING, { ratingLevel: 5 }),
  createConfig(fieldTypes.DATE),
  createConfig(fieldTypes.PHONE_NUMBER),
  createConfig(fieldTypes.EMAIL),
  createConfig(fieldTypes.FILE_UPLOAD, {
    file: {
      numberLimit: 1,
      singleFileSizeLimit: 1,
      type: '.pdf'
    }
  })
];

export const icons = {
  [fieldTypes.SHORT_ANSWER]: ShortAnswerIcon,
  [fieldTypes.LONG_ANSWER]: LongAnswerIcon,
  [fieldTypes.SINGLE_ANSWER]: SingleAnswerIcon,
  [fieldTypes.MULTIPLE_ANSWER]: MultipleAnswerIcon,
  [fieldTypes.RATING]: RatingIcon,
  [fieldTypes.DATE]: DateIcon,
  [fieldTypes.PHONE_NUMBER]: PhoneIcon,
  [fieldTypes.EMAIL]: EmailIcon,
  [fieldTypes.FILE_UPLOAD]: FileUploadIcon
};

// TODO: create the following types:
// - selectField { options: [{key: 'Nigeria', value: 'NG]}
// - socialLinks { options: [{key: 'facebook', value: 'facebook'}, {}, {}]}
