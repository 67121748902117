import { useState } from 'react';
import PropTypes from 'prop-types';
import { styled, Typography, Divider } from '@mui/material';
import { LoadingButton as Button } from '@mui/lab';
import { pxToRem } from 'utils/formatFont';
import headerBackground from 'assets/images/formPreviewBackground.png';
import successImage from 'assets/success.gif';
import alertImage from 'assets/gif/alert.gif';
import FormComponent from './FormComponent';

const FormRenderer = ({ formPayload, onSubmit, submitStatus, disableValidation }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [filledFields, setFilledFields] = useState({});
  const [triggerValidation, setTriggerValidation] = useState(false);
  const [extraLoader, setExtraLoader] = useState(false);
  const [errors, setErrors] = useState({});

  const isInvalid = () => {
    const required = formPayload.forms[currentIndex]
      .filter((field) => !!field.required)
      .map((f) => f.key);
    const filled = Object.keys(filledFields);
    const unfilled = required.filter((f) => !filled.includes(f));
    return !!unfilled.length;
  };

  const onNext = () => {
    if (!disableValidation && isInvalid()) {
      setTriggerValidation(true);
    } else {
      setCurrentIndex(currentIndex + 1);
      setTriggerValidation(false);
    }
  };

  const onPrevious = () => {
    setCurrentIndex(currentIndex - 1);
  };

  const handleSubmit = () => {
    if (!disableValidation && isInvalid()) {
      setTriggerValidation(true);
    } else {
      onSubmit(filledFields);
      setTriggerValidation(false);
    }
  };

  const onSetErrors = (errorObject) => {
    setErrors({ ...errors, ...errorObject });
  };

  const hasPrevious = formPayload.forms?.[currentIndex - 1]?.length;
  const hasNext = formPayload.forms?.[currentIndex + 1]?.length;
  const currentFormFields = formPayload?.forms?.[currentIndex];
  const { description, title, successMessageBody, successMessageTitle } = formPayload;

  const successBody = (
    <>
      <img src={successImage} alt="success tick" />
      <Typography variant="h3">{successMessageTitle}</Typography>
      <Typography
        sx={{ mb: '59px' }}
        variant="body1"
        dangerouslySetInnerHTML={{ __html: successMessageBody }}
      />
    </>
  );

  const closedBody = (
    <TestWrapper>
      <img src={alertImage} alt="success tick" style={{ display: 'inline-block' }} />
      <Typography variant="h3" sx={{ mb: '20px', color: '#323130' }}>
        Application Closed
      </Typography>

      <Typography sx={{ mb: '59px', color: '#6B6C7E' }} variant="body1">
        This application has been closed by the administrator. Kindly contact support at:{' '}
        <a
          href="mailto:support@tech4dev.com"
          style={{ color: '#6B6C7E', textDecoration: 'underline' }}
        >
          support@tech4dev.com
        </a>
      </Typography>
    </TestWrapper>
  );

  const deactivatedBody = (
    <TestWrapper>
      <img src={alertImage} alt="success tick" style={{ display: 'inline-block' }} />
      <Typography variant="h3" sx={{ mb: '20px', color: '#323130' }}>
        Application Deactivated
      </Typography>

      <Typography sx={{ mb: '59px', color: '#6B6C7E' }} variant="body1">
        This application has been deactivated by the administrator. Kindly contact support
        at:{' '}
        <a
          href="mailto:support@tech4dev.com"
          style={{ color: '#6B6C7E', textDecoration: 'underline' }}
        >
          support@tech4dev.com
        </a>
      </Typography>
    </TestWrapper>
  );

  const onFill = (payload) => {
    setFilledFields({ ...filledFields, ...payload });
  };

  const isLoading = submitStatus === 'submitting';
  const formBody = (
    <>
      {!hasPrevious ? (
        <>
          <Typography variant="body1">{description}</Typography>
          <Divider />
        </>
      ) : (
        <Typography variant="h2">Additional Information</Typography>
      )}

      <FormComponent
        fields={currentFormFields}
        onFill={onFill}
        filledFields={filledFields}
        setErrors={onSetErrors}
        triggerValidation={triggerValidation}
        setSubmitLoader={setExtraLoader}
      />
      <div className="controls">
        {hasPrevious ? (
          <Button
            disabled={isLoading}
            color="secondary"
            variant="outlined"
            onClick={onPrevious}
          >
            Previous
          </Button>
        ) : null}
        {hasNext ? (
          <Button
            disabled={isLoading}
            color="primary"
            variant="contained"
            onClick={onNext}
          >
            Next
          </Button>
        ) : (
          <Button
            loading={isLoading}
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={extraLoader}
          >
            Submit
          </Button>
        )}
      </div>
    </>
  );

  const returnBody = () => {
    if (formPayload.isClosed) return closedBody;
    if (formPayload.status === 'Deactivate') return deactivatedBody;
    if (submitStatus === 'success') return successBody;
    return formBody;
  };

  return (
    <Wrapper>
      <Header>
        <div className="content">
          <h1>{title}</h1>
        </div>
      </Header>
      <Body>{returnBody()}</Body>
    </Wrapper>
  );
};

export default FormRenderer;

FormRenderer.propTypes = {
  submitStatus: PropTypes.oneOf(['success', 'failed', 'submitting', 'idle']),
  formPayload: PropTypes.shape({
    // eslint-disable-next-line
    forms: PropTypes.array,
    description: PropTypes.string,
    title: PropTypes.string,
    successMessageBody: PropTypes.string,
    successMessageTitle: PropTypes.string
  }).isRequired
};

FormRenderer.defaultProps = {
  submitStatus: 'idle'
};

const Wrapper = styled('div')({
  width: 600,
  borderRadius: 4,
  transition: '0.3s ease-in-out',

  '& img[alt="success tick"]': {
    width: 73,
    marginBottom: 32
  },

  '& h1': {
    fontWeight: 600,
    fontSize: pxToRem(28),
    lineHeight: '36px'
  },

  '& h2': {
    fontWeight: 600,
    fontSize: pxToRem(18),
    lineHeight: '24px',
    marginBottom: 29
  },

  '& .buttons': {
    background: 'red'
  }
});

const Body = styled('div')({
  padding: 24,
  background: 'white',
  boxShadow: '0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
  transition: '0.3s ease-in-out',

  '& > .MuiDivider-root': {
    marginTop: 24,
    marginBottom: 37
  },

  '& .controls': {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 29
  }
});

const TestWrapper = styled('div')({
  textAlign: 'center'
});

const Header = styled('div')(({ theme }) => ({
  background: `url(${headerBackground})`,
  backgroundSize: 'cover',
  position: 'center',
  padding: '66px 0',
  paddingRight: 105,
  transition: '0.3s ease-in-out',
  '& .content': {
    color: 'white',
    padding: '16px 24px',
    background: theme.palette.primary.tertiary,
    borderRadius: '0px 100px 100px 0px'
  }
}));
