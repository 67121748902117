import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { SearchInput } from '../SearchInput';

export const QuickSearchBar = ({ searchValue, setTableParams }) => {
  const [searchText, setSearchText] = useState(searchValue ?? '');
  const classes = useStyles();

  const handleSearch = (value) => {
    setSearchText(value);
    setTableParams((prevParams) => {
      return {
        ...prevParams,
        pageNumber: 1,
        search: value
      };
    });
  };

  return (
    <div className={classes.tableHeader}>
      <SearchInput
        className="tableSearchInput"
        value={searchText}
        // disabled={!!searchBy}
        onChange={handleSearch}
      />
    </div>
  );
};

QuickSearchBar.propTypes = {
  setTableParams: PropTypes.func.isRequired
};

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    minHeight: 49,
    background: theme.palette.common.white,
    borderBottom: '1px solid #EDEBE9',
    display: 'flex',
    alignItems: 'center',
    padding: '8px 15px',

    '& .tableSearchInput': {
      maxWidth: 497,
      marginLeft: 'auto',
      background: '#F3F2F1'
    }
  }
}));
