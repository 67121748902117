/* eslint-disable */
import Api from 'utils/Api';
import handleApiError from 'utils/handleApiError';

const getCategories = async ({ queryKey }) => {
  const [, inputValue] = queryKey;
  const response = await Api.get(`/programmes/categories?search=${inputValue}`);
  return response.data;
};

const getProgram = async ({ queryKey }) => {
  try {
    const [, programId] = queryKey;
    const { data } = await Api.get(`/programmes/${programId}`);
    return data;
  } catch (e) {
    handleApiError(e);
  }
};

const createProgram = async (payload) => {
  const { data } = await Api.post('/programmes', payload);
  return data;
};

const updateProgram = async (payload) => {
  const { data } = await Api.put(`/programmes/${payload.programId}`, payload);
  return data;
};

const deleteProgram = async (id) => {
  try {
    const { data } = await Api.delete(`/programmes/${id}`);
    return data;
  } catch (e) {
    handleApiError(e);
  }
};

const getLearningTracks = async (programId) => {
  try {
    const { data } = await Api.get(`/programmes/${programId}/learning-track`);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

const getLearningTracksInfinite = async ({ queryKey, pageParam }) => {
  try {
    const [, programId, params] = queryKey;

    const { data } = await Api.get(`/programmes/${programId}/learning-track`, {
      params: {
        ...params,
        pageNumber: pageParam ?? 1
      }
    });

    return data;
  } catch (e) {
    handleApiError(e);
  }
};

const createLearningTrack = async (payload) => {
  const { programId, ...values } = payload;
  const { data } = await Api.post(`/programmes/${programId}/learning-track`, values);
  return data;
};

const editLearningTrack = async (payload) => {
  const { id, ...values } = payload;
  const { data } = await Api.put(`/programmes/learning-track/${id}`, values);
  return data;
};

const deleteLearningTrack = async (id) => {
  const { data } = await Api.delete(`/programmes/learning-track/${id}`);
  return data;
};

const createCFASettings = async (payload) => {
  const { data } = await Api.post('/activities/call-for-application', payload);
  return data;
};

const duplicateCFA = async (id) => {
  const { data } = await Api.post('/activities/call-for-application/duplicate', { id });
  return data;
};

const updateCFAApplicantStage = async (payload) => {
  const { applicantId, stageId } = payload;

  const { data } = await Api.put(
    `/activities/applicants/${applicantId}/stage?stageId=${stageId}`
  );
  return data;
};

const updateCFAApplicantStatus = async ({ status, applicantId }) => {
  const { data } = await Api.put(`/activities/applicants/${applicantId}/${status}`);
  return data;
};

const updateMultipleCFAApplicantStatus = async ({
  callForApplicationId,
  ids,
  status
}) => {
  const { data } = await Api.put(`/activities/applicants/bulk-${status}`, {
    callForApplicationId,
    ids
  });
  return data;
};

const updateMultipleCFAApplicantsStage = async (payload) => {
  const { applicantsId: ids, stageId } = payload;

  const { data } = await Api.put(`/activities/applicants/stage`, {
    ids,
    stageId
  });
  return data;
};

const updateCFASettings = async (payload) => {
  const { id, ...values } = payload;
  const { data } = await Api.put(`/activities/call-for-application/${id}`, values);
  return data;
};

const getCFASettings = async ({ queryKey }) => {
  const [, activityId, params] = queryKey;

  const { data } = await Api.get(`/activities/call-for-application/${activityId}`, {
    params
  });
  return data;
};

const getCFAApplicantFormResponse = async ({ queryKey }) => {
  const [, applicantId, formId] = queryKey;

  const { data } = await Api.get(`/activities/form/${formId}/applicant/${applicantId}`);
  return data;
};

const getCFAApplicant = async ({ queryKey }) => {
  const [, callForApplicationId, applicantId] = queryKey;
  const { data } = await Api.get(
    `/activities/call-for-application/${callForApplicationId}/applicants/${applicantId}`
  );
  return data;
};

const getLearningTrackFacilitators = async ({ queryKey }) => {
  const [, learningTrackId, params] = queryKey;
  const { data } = await Api.get(
    `/programmes/learning-track/${learningTrackId}/facilitators`,
    { params }
  );
  return data;
};

const getOtherLearningTracks = async ({ queryKey }) => {
  const [, programId] = queryKey;
  const { data } = await Api.get(`/programmes/${programId}/other-learning-track`);
  return data;
};

const getLearningTrack = async ({ queryKey }) => {
  const [, learningTrackId] = queryKey;
  const { data } = await Api.get(`/programmes/learning-track/${learningTrackId}`);
  return data;
};

const getLearningTrackStat = async ({ queryKey }) => {
  const [, learningTrackId] = queryKey;
  const { data } = await Api.get(`/programmes/learning-track/${learningTrackId}/stat`);
  return data;
};

const getLearningTrackBeneficiaries = async ({ queryKey }) => {
  try {
    const [, { learningTrackId, ...params }] = queryKey;
    const { data } = await Api.get(`/programmes/applicants/${learningTrackId}`, {
      params
    });
    return data;
  } catch (e) {
    throw new Error(handleApiError(e));
  }
};

const getProgramActivities = async ({ queryKey, pageParam }) => {
  try {
    const [, programId, params] = queryKey;

    const { data } = await Api.get(`/activities/programme/${programId}`, {
      params: {
        ...params,
        pageNumber: pageParam ?? 1
      }
    });
    return data;
  } catch (e) {
    handleApiError(e);
  }
};

const deleteCFActivity = async (id) => {
  const { data } = await Api.delete(`/activities/call-for-application/${id}`);
  return data;
};

const createActivityCustomForm = async (payload) => {
  const { activityId, ...values } = payload;
  const { data } = await Api.post(`/activities/${activityId}/custom-form`, values);
  return data;
};

const createActivityDefaultField = async (payload) => {
  try {
    const { id, ...values } = payload;
    const { data } = await Api.post(`/activities/${id}/default-form`, values);
    return data;
  } catch (e) {
    handleApiError(e);
  }
};

const getCallForApllication = async ({ queryKey }) => {
  const [, activityId] = queryKey;
  const response = await Api.get(`/activities/call-for-application/${activityId}`);
  return response.data;
};

const getCustomFormFields = async ({ queryKey }) => {
  const [, { formId, ...queryParams }] = queryKey;
  const response = await Api.get(
    `/activities/dynamic-form/${formId ?? queryParams.previewFormId}`,
    {
      params: queryParams
    }
  );
  return response.data;
};

const getActivityDefaultFields = async ({ queryKey }) => {
  try {
    const [, formId, params] = queryKey;
    const { data } = await Api.get(
      `/activities/dynamic-form/${formId ?? params.previewFormId}`,
      { ...params }
    );

    return data;
  } catch (e) {
    throw new Error(handleApiError(e));
  }
};

const getProgramStat = async ({ queryKey }) => {
  try {
    const [, programId] = queryKey;
    const { data } = await Api.get(`/programmes/${programId}/target-stat`);
    return data;
  } catch (e) {
    handleApiError(e);
  }
};

const getProgramLearnTrackStat = async ({ queryKey }) => {
  try {
    const [, programId] = queryKey;
    const { data } = await Api.get(`/programmes/${programId}/learning-track-stat`);
    return data;
  } catch (e) {
    handleApiError(e);
  }
};

const getCFAParticipants = async ({ queryKey }) => {
  try {
    const [, { callForApplicationId, ...params }] = queryKey;

    const { data } = await Api.get(
      `/activities/call-for-application/${callForApplicationId}/applicants`,
      {
        params
      }
    );
    return data;
  } catch (e) {
    handleApiError(e);
  }
};

const getCallForApplication = async ({ queryKey }) => {
  try {
    const [, callForApplicationId] = queryKey;
    const { data } = await Api.get(
      `/activities/call-for-application/${callForApplicationId}`
    );
    return data;
  } catch (e) {
    handleApiError(e);
  }
};

const getSubmissions = async ({ queryKey }) => {
  try {
    const [, callForApplicationId] = queryKey;
    const { data } = await Api.get(
      `/activities/call-for-application/${callForApplicationId}/submision-stat`
    );
    return data;
  } catch (e) {
    handleApiError(e);
  }
};

const getStages = async ({ queryKey }) => {
  try {
    const [, callForApplicationId] = queryKey;
    const { data } = await Api.get(
      `/activities/call-for-application/${callForApplicationId}/stages`
    );
    return data;
  } catch (e) {
    handleApiError(e);
  }
};

const activateCFA = async (id) => {
  try {
    await Api.put(`/activities/call-for-application/${id}/activate`);
  } catch (e) {
    handleApiError(e);
  }
};

const deactivateCFA = async (id) => {
  try {
    await Api.put(`/activities/call-for-application/${id}/suspend`);
  } catch (e) {
    handleApiError(e);
  }
};

const getProgramAssessmentStat = async (applicantId, programId) => {
  try {
    const { data } = await Api.get(
      `/assessments/applicant/${applicantId}/program/${programId}/assessment/stats`
    );
    return data;
  } catch (e) {
    handleApiError(e);
  }
};

const createEvent = async (params) => {
  const data = await Api.post(`/events`, params);
  return data;
};

const duplicateEvent = async (id) => {
  const data = await Api.post(`/events/duplicate`, { id });
  return data;
};

const addEventDetails = async (payload) => {
  const id = payload.get('activityId');
  try {
    const { data } = await Api.put(`/events/${id}/details`, payload);
    return data;
  } catch (e) {
    handleApiError(e);
  }
};

const getEventSettings = async (id) => {
  try {
    const { data } = await Api.get(`/events/${id}`);
    return data;
  } catch (e) {
    handleApiError(e);
  }
};

const updateEventSettings = async (params) => {
  try {
    const { data } = await Api.put(`/events/${params.id}`, params);
    return data;
  } catch (e) {
    handleApiError(e);
  }
};

const deleteEvent = async (id) => {
  const { data } = await Api.delete(`/events/${id}`);
  return data;
};

export const getCFASettingsNoQuery = async ({ activityId, params }) => {
  try {
    const response = await Api.get(`/activities/call-for-application/${activityId}`, {
      params
    });
    return response;
  } catch (e) {
    handleApiError(e);
  }
};

export {
  duplicateCFA,
  duplicateEvent,
  getCategories,
  createProgram,
  updateProgram,
  deleteProgram,
  getProgram,
  getProgramStat,
  getProgramLearnTrackStat,
  getLearningTracks,
  getLearningTracksInfinite,
  createLearningTrack,
  editLearningTrack,
  deleteLearningTrack,
  createCFASettings,
  updateCFASettings,
  getCFASettings,
  deleteCFActivity,
  getProgramActivities,
  createActivityCustomForm,
  getCallForApllication,
  getCustomFormFields,
  createActivityDefaultField,
  getActivityDefaultFields,
  getCFAParticipants,
  getCallForApplication,
  getSubmissions,
  getStages,
  activateCFA,
  deactivateCFA,
  getCFAApplicantFormResponse,
  getCFAApplicant,
  updateCFAApplicantStage,
  updateCFAApplicantStatus,
  updateMultipleCFAApplicantStatus,
  updateMultipleCFAApplicantsStage,
  getLearningTrackFacilitators,
  getLearningTrack,
  getLearningTrackStat,
  getLearningTrackBeneficiaries,
  getOtherLearningTracks,
  getProgramAssessmentStat,
  createEvent,
  addEventDetails,
  getEventSettings,
  updateEventSettings,
  deleteEvent
};
