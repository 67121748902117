import { Controller } from 'react-hook-form';
import { useRichEditor } from 'hooks/useRichEditor';
import { Editor } from './Editor';

export const ControlEditor = ({ control, name, label, defaultMessageBody }) => {
  const { editorState, onEditorChange, html } = useRichEditor(defaultMessageBody);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange } }) => (
        <Editor
          label={label}
          editorState={editorState}
          setEditorState={(state) => {
            onChange(html);
            onEditorChange(state);
          }}
        />
      )}
    />
  );
};
