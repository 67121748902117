/* eslint-disable */
import { useState, useMemo } from 'react';
import { Box, ClickAwayListener, styled } from '@mui/material';
import { pxToRem } from 'utils/formatFont';
import Api from 'utils/Api';
import throttle from 'lodash/throttle';
import InputField from './InputField';

const LiveAutocomplete = ({
  label,
  defaultValue,
  header,
  queryKey = 'search',
  queryParams = {},
  noOptionsNode,
  url,
  onOptionSelect,
  onInputChange,
  getOptionLabel,
  selectOnly,
  freeSolo,
  clearOnSelect,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState(defaultValue);
  const [options, setOptions] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [didSelect, setDidSelect] = useState(false);

  const apiRequest = async (searchKey) => {
    const { data } = await Api.get(url, {
      params: {
        ...queryParams,
        [queryKey]: searchKey
      }
    });
    setOptions(data.data);
  };
  const throttledRequest = throttle(apiRequest, 300, { trailing: false });
  const getData = useMemo(() => throttledRequest, []);

  const handleInputChange = (e) => {
    if (url) getData(e.target.value);
    if (freeSolo) onOptionSelect(e.target.value);
    setInputValue(e.target.value);
    setDidSelect(false);
  };

  const handleOptionClick = (value) => {
    setDidSelect(true);
    onOptionSelect(value);
    if (clearOnSelect) setInputValue('');
    else setInputValue(getOptionLabel(value));

    setShowOptions(false);
  };

  const handleClickAway = (e) => {
    !defaultValue && selectOnly && !didSelect && setInputValue('');
    setShowOptions(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Wrapper>
        <InputField
          fullWidth
          label={label}
          onChange={handleInputChange}
          value={inputValue}
          onFocus={() => setShowOptions(true)}
          {...rest}
        />

        {showOptions ? (
          <Options>
            {!options.length && noOptionsNode ? (
              <div className="header" onClick={() => setShowOptions(false)}>
                {noOptionsNode}
              </div>
            ) : header ? (
              <div className="header">{header}</div>
            ) : null}

            {options.map((option) => (
              <li
                onClick={() => handleOptionClick(option)}
                key={option.id || getOptionLabel(option)}
              >
                {getOptionLabel(option)}
              </li>
            ))}
          </Options>
        ) : null}
      </Wrapper>
    </ClickAwayListener>
  );
};

export default LiveAutocomplete;

const Wrapper = styled(Box)(() => ({
  position: 'relative'
}));

const Options = styled('ul')(({ theme }) => ({
  listStyleType: 'none',
  background: 'white',
  position: 'absolute',
  top: 64,
  minWidth: '260px',
  maxWidth: '100%',
  borderRadius: '2px',
  boxShadow: ' 0px 0.6px 1.8px rgba(0, 0, 0, 0.1), 0px 3.2px 7.2px rgba(0, 0, 0, 0.13)',
  zIndex: 3000,
  fontSize: pxToRem(14),
  color: theme.palette.text.primary,
  '& li': {
    padding: '6px 12px',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.grey.light
    }
  },

  '& .header': {
    padding: '6px 12px',
    color: theme.palette.primary.main,
    fontSize: pxToRem(12),
    borderBottom: '1px solid #EDEBE9',
    '& *': {
      fontSize: pxToRem(12)
    }
  }
}));
