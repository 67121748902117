import ShortAnswer from './ShortAnswer';
import LongAnswer from './LongAnswer';
import SingleAnswer from './SingleAnswer';
import MultipleAnswer from './MultipleAnswer';
import DateComponent from './DateComponent';
import RatingComponent from './RatingComponent';
import FileUpload from './FileUpload';
import Links from './Links';
import PhoneNumber from './PhoneNumber';
import SelectField from './SelectField';

import { fieldTypes } from '../../FormBuilder/constants';

export default {
  [fieldTypes.SHORT_ANSWER]: ShortAnswer,
  [fieldTypes.LONG_ANSWER]: LongAnswer,
  [fieldTypes.SINGLE_ANSWER]: SingleAnswer,
  [fieldTypes.MULTIPLE_ANSWER]: MultipleAnswer,
  [fieldTypes.DATE]: DateComponent,
  [fieldTypes.RATING]: RatingComponent,
  [fieldTypes.FILE_UPLOAD]: FileUpload,
  [fieldTypes.LINKS]: Links,
  [fieldTypes.PHONE_NUMBER]: PhoneNumber,
  [fieldTypes.SELECT]: SelectField,
  [fieldTypes.EMAIL]: ShortAnswer
};
