/* eslint-disable */
import { v4 as uuid } from 'uuid';
import { Typography, styled } from '@mui/material';
import { pxToRem } from 'utils/formatFont';
import { fieldConfigs as options, icons } from './constants';

const CustomFormOptions = ({ onAddField }) => {
  const handleClick = (option) => () => {
    const uniqueId = uuid();
    onAddField({
      options: [],
      ...option,
      id: uniqueId,
      key: uniqueId
    });
  };
  return (
    <Wrapper>
      <Typography>Fields</Typography>
      {options.map((option) => {
        const Icon = icons[option.type];
        return (
          <Option key={option.id} onClick={handleClick(option)}>
            <Icon />
            <p>{option.label}</p>
          </Option>
        );
      })}
    </Wrapper>
  );
};

export default CustomFormOptions;

const Wrapper = styled('div')(({ theme }) => ({
  padding: 16,
  minHeight: '100%',
  background: '#F9FAFB',

  '& > .MuiTypography-root': {
    marginBottom: 10
  }
}));

const Option = styled('div')(() => ({
  background: 'white',
  padding: 10,
  paddingBottom: 16,
  width: 216,
  boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
  marginTop: 10,
  display: 'flex',
  cursor: 'pointer',
  userSelect: 'none',

  '& > *': {
    display: 'block',
    fontSize: pxToRem(14),
    margin: 'auto 0'
  },

  '& >:first-child': {
    width: 'auto',
    height: 23,
    marginRight: 8
  }
}));
