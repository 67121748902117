import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import ProgramStarterCard from 'reusables/ProgramStarterCard';
import { styled, Button, Zoom } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ProgramPaths } from 'routes/paths';
import TimeBoundImg from 'assets/time-bound.svg';
import SelfPacedImg from 'assets/self-paced.svg';
import LearningTrackImg from 'assets/learning-track.svg';
import NoLearningTrackImg from 'assets/no-learning-track.svg';

const CARD_STATE = {
  DELIVERY_METHOD: 1,
  TRACK_TYPE: 2
};

const ChoiceCards = ({ next }) => {
  const [currCard, setCurrCard] = useState(CARD_STATE.DELIVERY_METHOD);
  const navigate = useNavigate();

  const setFormOption = (keyValuePair) => {
    const existing = window.sessionStorage.getItem('formOptions');
    if (!existing) {
      const val = JSON.stringify(keyValuePair);
      window.sessionStorage.setItem('formOptions', val);
    } else {
      const val = {
        ...JSON.parse(existing),
        ...keyValuePair
      };

      const newVal = JSON.stringify(val);
      window.sessionStorage.setItem('formOptions', newVal);
    }
  };

  const deliveryCards = [
    {
      title: 'Time-bound',
      desc: 'Does this Program have a Learning Track?. Does this Program have a Learning Track.',
      continueFn: () => {
        setFormOption({ deliveryMethod: 'timeBound' });
        setCurrCard(2);
      },
      img: TimeBoundImg
    },
    {
      title: 'Self-paced',
      desc: 'Does this Program have a Learning Track?. Does this Program have a Learning Track.',
      continueFn: () => {
        // setOptions({ ...options, deliveryMethod: 'selfPaced' });
        // setCurrCard(2);
      },
      img: SelfPacedImg,
      disabled: true
    }
  ];

  const trackCards = [
    {
      title: 'With Learning Track',
      desc: 'Does this program have a learning track? If yes, then click here to proceed',
      continueFn: () => {
        setFormOption({ hasLearningTrack: true });
        next();
      },
      img: LearningTrackImg
    },
    {
      title: 'Without Learning Track',
      desc: 'Does this program have a learning track? If no, then click here to proceed',
      continueFn: () => {
        // setOptions({ ...options, hasLearningTrack: false });
        // navigate(`${ProgramPaths.CREATE_PROGRAM_BASE}/forms`);
      },
      img: NoLearningTrackImg,
      disabled: true
    }
  ];

  return (
    <Container>
      <Typography component="h1" className="pageTitle">
        Create Program
      </Typography>
      <Zoom
        in={[1, 2].includes(currCard)}
        style={{ transitionDelay: [1, 2].includes(currCard) ? '250ms' : '0ms' }}
      >
        <Typography variant="subTitle2" className="subTitle">
          {currCard === 1
            ? 'Select the delivery method?'
            : 'Does this program have a learning track?'}
        </Typography>
      </Zoom>
      <div className="cardsContainer">
        {currCard === CARD_STATE.DELIVERY_METHOD && (
          <>
            {deliveryCards.map((card) => (
              <ProgramStarterCard
                key={`${card.title}_${card.desc}`}
                title={card.title}
                desc={card.desc}
                continueFn={card.continueFn}
                disabled={card.disabled}
                img={card.img}
                appear={currCard === CARD_STATE.DELIVERY_METHOD}
              />
            ))}
          </>
        )}
        {currCard === CARD_STATE.TRACK_TYPE && (
          <>
            {trackCards.map((card) => (
              <ProgramStarterCard
                key={`${card.title}_${card.desc}`}
                title={card.title}
                desc={card.desc}
                continueFn={card.continueFn}
                disabled={card.disabled}
                img={card.img}
                appear={currCard === CARD_STATE.TRACK_TYPE}
              />
            ))}
          </>
        )}
      </div>
      {currCard === CARD_STATE.TRACK_TYPE ? (
        <div>
          <Button
            color="secondary"
            onClick={() => setCurrCard(CARD_STATE.DELIVERY_METHOD)}
            size="large"
            variant="outlined"
          >
            Back
          </Button>
        </div>
      ) : (
        <div>
          <Button
            color="secondary"
            onClick={() => navigate(`${ProgramPaths.PROGRAMS}`)}
            size="large"
            variant="outlined"
          >
            Cancel
          </Button>
        </div>
      )}
    </Container>
  );
};

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: 36,

  '& .pageTitle': {
    fontWeight: 600,
    fontSize: 28,
    color: theme.palette.text.tertiary,
    marginBottom: 40
  },

  '& .subTitle': {
    color: theme.palette.text.secondary,
    fontSize: 18
  },

  '& .cardsContainer': {
    display: 'flex',
    marginTop: 32,
    marginBottom: 42,

    '& > div:not(:first-of-type)': {
      marginLeft: 24
    }
  }
}));

export default ChoiceCards;
