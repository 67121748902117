import { lazy } from 'react';
import { MainLayout, ProgramLayout } from 'layouts';
import { ProgramPaths } from './paths';

export const routes = [
  {
    path: '/*',
    exact: true,
    component: lazy(() => import('modules/Auth'))
  },
  {
    path: '/ui-docs',
    exact: true,
    component: lazy(() => import('../uiDocs'))
  },
  {
    path: `${ProgramPaths.OVERVIEW}/*`,
    component: lazy(() => import('modules/Overview')),
    layout: MainLayout,
    isAuthenticated: true
  },
  {
    path: `${ProgramPaths.PROGRAMS}/*`,
    component: lazy(() => import('modules/Programs')),
    layout: MainLayout,
    isAuthenticated: true
  },
  {
    path: `${ProgramPaths.PROGRAM}/*`,
    component: lazy(() => import('modules/ProgramMutation')),
    layout: ProgramLayout,
    isAuthenticated: true
  },
  {
    path: `${ProgramPaths.ACTIVITIES}/*`,
    component: lazy(() => import('modules/Activities')),
    layout: MainLayout,
    isAuthenticated: true
  },
  {
    path: `${ProgramPaths.EVENTS}/*`,
    component: lazy(() => import('modules/Events')),
    layout: MainLayout,
    isAuthenticated: true
  },
  {
    path: `${ProgramPaths.PEOPLE}/*`,
    component: lazy(() => import('modules/People')),
    layout: MainLayout,
    isAuthenticated: true
  },
  {
    path: `${ProgramPaths.SETTINGS}/*`,
    component: lazy(() => import('modules/Settings')),
    layout: MainLayout,
    isAuthenticated: true
  },
  {
    path: `${ProgramPaths.SUPPORT}/*`,
    component: lazy(() => import('modules/Support')),
    layout: MainLayout,
    isAuthenticated: true
  },
  {
    path: `${ProgramPaths.FORMS}/*`,
    component: lazy(() => import('modules/Forms')),
    isAuthenticated: false
  },
  {
    path: '*',
    component: lazy(() => import('components/NotFound'))
  },
  {
    path: `${ProgramPaths.PROFILE}/*`,
    layout: MainLayout,
    isAuthenticated: true,
    component: lazy(() => import('modules/Profile'))
  },
  // {
  //   path: `assesmentdetials`,
  //   layout: MainLayout,
  //   // isAuthenticated: true,
  //   component: lazy(() => import('../modules/Activities/views/AssessmentDetails.temp'))
  // }
  {
    path: `${ProgramPaths.BENEFICIARY}/*`,
    isAuthenticated: true,
    component: lazy(() => import('modules/Beneficiary'))
  }
];
