import { useState, useReducer, useEffect } from 'react';
import { FormGroup, FormHelperText, Rating } from '@mui/material';
import { validations } from '../helper.formRenderer';
import InputLabel from '../../InputLabel';

const RatingComponent = ({ field, onFill, triggerValidation, existingValue = 0 }) => {
  const validator = validations[field.type];
  const initialState = {
    value: existingValue,
    errorMessage: ''
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'inputChange':
        return { value: action.payload, errorMessage: validator(action.payload, field) };

      case 'validate':
        return { ...state, errorMessage: validator(state.value, field) };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const { value, errorMessage } = state;

  const [, setHover] = useState(-1);

  useEffect(() => {
    if (triggerValidation) dispatch({ type: 'validate' });
  }, [triggerValidation]);

  const onChange = (e, newValue) => {
    dispatch({ type: 'inputChange', payload: newValue });
    onFill({ [field.key]: { value: newValue.toString(), id: field.id } });
  };

  return (
    <>
      <FormGroup>
        <InputLabel error={!!errorMessage}>{field.label}</InputLabel>
        <Rating
          max={field.level || field.ratingLevels}
          precision={1}
          value={value}
          onChange={onChange}
          onChangeActive={(event, newHover) => {
            setHover(newHover);
          }}
        />
      </FormGroup>
      <FormHelperText error>{errorMessage}</FormHelperText>
    </>
  );
};

export default RatingComponent;
