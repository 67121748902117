import { Card, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { makeStyles } from '@mui/styles';

export const FormActionCard = ({ isLoading, handleNext }) => {
  const classes = useStyles();

  return (
    <Card className={classes.actionBox}>
      <Button variant="contained">Continue Later</Button>
      <LoadingButton
        loading={isLoading}
        color="primary"
        variant="contained"
        onClick={handleNext}
        type="submit"
      >
        Next
      </LoadingButton>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  actionBox: {
    marginTop: 24,
    width: '100%',
    maxWidth: 600,
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 16,

    '& > * ': {
      marginLeft: '16px',
      borderRadius: '2px'
    },
    '& .MuiButton-root': {
      padding: '6px 20px'
    },
    '& .Mui-disabled': {
      background: theme.palette.grey.light,
      color: '#A19F9D'
    }
  }
}));
