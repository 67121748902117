import { Controller } from 'react-hook-form';
import TextArea from './TextArea';

export const ControlTextArea = ({
  label,
  reset,
  control,
  defaultValue,
  controllerprops,
  ...restInputProps
}) => {
  return (
    <Controller
      control={control}
      name="description"
      {...controllerprops}
      render={({ field: { name, value, ref, ...rest } }) => (
        <TextArea
          label={label}
          name={name}
          value={value}
          defaultValue={defaultValue}
          minRows={5}
          maxRows={5}
          maxLength={250}
          inputRef={ref}
          {...restInputProps}
          {...rest}
        />
      )}
    />
  );
};
