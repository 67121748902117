import { Switch as MuiSwitch, FormControlLabel } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

const Switch = ({ label, rightText, ...rest }) => {
  const classes = useStyles();
  const { value, onChange, inputRef, ...restProps } = rest;

  return (
    <FormControlLabel
      className={classes.formControlRoot}
      sx={{ m: 0 }}
      control={
        <div className={classes.switchRoot}>
          <MuiSwitch
            disableRipple
            className={classes.switch}
            checked={value}
            onChange={onChange}
            inputRef={inputRef}
          />
          {rightText && <span className="switchRightText">{rightText}</span>}
        </div>
      }
      label={label}
      {...restProps}
    />
  );
};

Switch.propTypes = {
  label: PropTypes.string
};

Switch.defaultProps = {
  label: ''
};

const useStyles = makeStyles((theme) => ({
  switchRoot: {
    display: 'flex',
    alignItems: 'center',

    '& .switchRightText': {
      display: 'inline-block',
      marginLeft: 8,
      fontSize: 14,
      fontWeight: 400
    }
  },

  switch: {
    width: 40,
    height: 20,
    borderRadius: 10,
    padding: 0,
    display: 'flex',

    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(22px)',
        color: '#fff',
        '& .MuiSwitch-thumb': {
          backgroundColor: theme.palette.common.white
        },

        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          border: `1px solid ${theme.palette.primary.main}`
        }
      }
    },

    '& .MuiSwitch-thumb': {
      width: 12,
      height: 12,
      transform: 'translateY(1.5px)',
      backgroundColor: '#605E5C',
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200
      })
    },

    '& .MuiSwitch-track': {
      border: '1px solid #8A8886',
      borderRadius: 10,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
      boxSizing: 'border-box'
    }
  },

  formControlRoot: {
    marginBottom: 8,
    '&.MuiFormControlLabel-root': {
      '& .MuiFormControlLabel-label': {
        fontSize: 14,
        fontWeight: 600,
        textTransform: 'capitalize'
      }
    },

    '&.MuiFormControlLabel-labelPlacementStart': {
      '& .MuiFormControlLabel-label': {
        marginRight: 8
      }
    },

    '&.MuiFormControlLabel-labelPlacementTop': {
      alignItems: 'flex-start',
      marginBottom: 0,

      '& .MuiFormControlLabel-label': {
        marginBottom: 5
      }
    }
  }
}));

export default Switch;
