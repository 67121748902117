import { Autocomplete, styled } from '@mui/material';
import PropTypes from 'prop-types';

import { pxToRem } from 'utils/formatFont';
import InputField from './InputField';

const PaperComponent = styled('div')(({ theme }) => ({
  background: 'white',
  borderRadius: 0,
  width: 260,
  padding: 0,
  boxShadow: '0px 0.6px 1.8px rgba(0, 0, 0, 0.1), 0px 3.2px 7.2px rgba(0, 0, 0, 0.13)',

  '& ul': {
    color: theme.palette.text.primary,
    fontSize: pxToRem(14),
    padding: 0
  }
}));

const ListHeader = styled('div')(({ theme }) => ({
  background: 'white',
  borderRadius: 0,
  color: theme.palette.primary.main,
  fontSize: pxToRem(12),
  padding: '12px 11px 12px 14px',
  borderBottom: `1px solid ${theme.palette.grey.dashedBorder}`
}));

const style = {
  '& .MuiOutlinedInput-root.MuiInputBase-root': {
    padding: '4px'
  },
  '& .MuiSvgIcon-root': {
    display: 'none'
  }
};

const AutoCompleteInputField = ({ options, label, helperText, error, ...rest }) => {
  return (
    <Autocomplete
      sx={style}
      size="small"
      options={options}
      PaperComponent={(params) => (
        <PaperComponent {...params}>
          <ListHeader>Results</ListHeader>
          {params.children}
        </PaperComponent>
      )}
      renderInput={(params) => (
        <InputField
          label={label}
          helperText={helperText}
          error={error}
          {...params}
          sx={{ p: 0 }}
        />
      )}
      {...rest}
    />
  );
};

AutoCompleteInputField.propTypes = {
  options: PropTypes.arrayOf(PropTypes.arrayOf([PropTypes.string, PropTypes.number]))
    .isRequired,
  label: '',
  helperText: PropTypes.string,
  error: PropTypes.oneOf([PropTypes.string, undefined, null, false])
};

AutoCompleteInputField.defaultProps = {
  label: '',
  helperText: '',
  error: undefined
};

export default AutoCompleteInputField;
