import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

export const CardWrapper = ({ children, radius, ...rest }) => {
  const classes = useStyles({ radius });
  const { className } = rest;

  return (
    <div {...rest} className={clsx(classes.card, className)}>
      {children}
    </div>
  );
};

CardWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  radius: PropTypes.number
};

CardWrapper.defaultProps = {
  radius: 0
};

const useStyles = makeStyles({
  card: {
    background: '#fff',
    width: '100%',
    boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
    borderRadius: ({ radius }) => radius
  }
});
