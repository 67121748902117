import Api from 'utils/Api';
import Auth from 'utils/Auth';
import handleApiError from 'utils/handleApiError';

const signin = (data) => {
  const response = Api.post('/auths/login/', data);
  return response;
};

const signup = (data) => {
  const response = Api.post('/users/register/', data);
  return response;
};

const forgotPassword = (data) => {
  const response = Api.post('/auths/reset-password/', data);
  return response;
};

const verifyToken = (data) => {
  const response = Api.post('/auths/verify-token/', data);
  return response;
};

const updateUser = (data) => {
  const response = Api.post('/auths/set-password/', data);
  return response;
};

const updateAdmin = ({ adminId, ...data }) => {
  const response = Api.put(`/users/edit-admin/${adminId}/`, data);
  return response;
};

const createUser = (data) => {
  const response = Api.post('/users/register/', data);
  return response;
};

export function getUsers({ queryKey }) {
  const [, queryParams] = queryKey;
  return Api.get(`/users/`, {
    params: queryParams
  });
}

export async function getAdmins({ queryKey }) {
  try {
    const [, params] = queryKey;
    const { data } = await Api.get('/users/admins/', { params });
    return data;
  } catch (e) {
    throw new Error(handleApiError(e));
  }
}

export function activateAdmin({ queryKey }) {
  const [, queryParams] = queryKey;
  return Api.put(`/users/${queryParams.id}/activate/`);
}

export function deactivateAdmin({ queryKey }) {
  const [, queryParams] = queryKey;
  return Api.put(`/users/${queryParams.id}/suspend/`);
}

export async function getAdminsStat({ queryKey }) {
  try {
    const [, params] = queryKey;
    const { data } = await Api.get('/users/admin-stats/', { params });
    return data;
  } catch (e) {
    throw new Error(handleApiError(e));
  }
}

const getCurrentUser = () => {
  const decodedToken = Auth.getDecodedJwt();
  const token = Auth.getToken();

  if (token) {
    Api.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete Api.defaults.headers.common.Authorization;
  }

  const response = Api.get(`/users/${decodedToken.UserId}`);
  return response;
};

const AuthApis = {
  signin,
  signup,
  forgotPassword,
  updateUser,
  verifyToken,
  getCurrentUser,
  getAdminsStat,
  getAdmins,
  createUser,
  updateAdmin,
  activateAdmin,
  deactivateAdmin
};

export default AuthApis;
