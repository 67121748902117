/* eslint-disable */
import { v4 as uuid } from 'uuid';
import { Button, styled } from '@mui/material';
import { ReactComponent as RatingStar } from 'assets/RatingStar.svg';
import { pxToRem } from 'utils/formatFont';
import InputField from '../InputField';
import TextArea from '../TextArea';
import InputLabel from '../InputLabel';
import Radio from '../Radio';
import Checkbox from '../Checkbox';
import DatePicker from '../DatePicker';
import Select from '../Select';
import { fieldTypes } from './constants';
import { ReactComponent as UploadIcon } from 'assets/UploadBottom.svg';

// =========== SHORT ANSWER ==============
export const ShortAnswer = ({ field }) => (
  <InputField fullWidth disabled value={field.placeholder} sx={{ mb: 2 }} />
);

// =========== LONG ANSWER ==============
export const LongAnswer = ({ field }) => (
  <TextArea
    maxRows={5}
    minRows={4}
    placeholder={field.placeholder}
    style={{ marginBottom: 8 }}
  />
);

// =========== SINGLE & MULTIPLE ANSWERS ==============
export const Option = ({ field, updateField }) => {
  const onAddField = () => {
    updateField({ options: [...field.options, { label: '' }] }, field.id);
  };

  const handleInputChange = (index, value) => {
    const options = field.options.map((option) => {
      if (field.options.indexOf(option) === index) {
        return { label: value, key: value, value };
      }
      return option;
    });

    updateField({ options }, field.id);
  };
  return (
    <>
      <InputLabel>{field.label}</InputLabel>
      {field?.options?.map((option, index) => (
        <OptionsDiv key={uuid()}>
          {field.type === fieldTypes.SINGLE_ANSWER ? (
            <Radio checked={false} />
          ) : (
            <Checkbox checked={false} />
          )}
          <InputField
            placeholder="Answer here..."
            defaultValue={option.label}
            onBlur={(e) => handleInputChange(index, e.target.value)}
          />
        </OptionsDiv>
      ))}
      <Button color="primary" onClick={onAddField}>
        + Add option
      </Button>
    </>
  );
};

// ===========  RATING ==============
export const Rating = ({ field, updateField }) => {
  const handleChange = (ratingLevels) => {
    updateField({ ratingLevels }, field.id);
  };
  const ratings = new Array(field.ratingLevels).fill(
    <RatingStar style={{ margin: '16px 10px 12px 0' }} />
  );
  return (
    <>
      {ratings}
      <div style={{ marginBottom: 8, display: 'flex' }}>
        <InputLabel style={{ display: 'block', margin: 'auto 0', marginRight: 16 }}>
          Levels
        </InputLabel>
        <Select
          value={field.ratingLevels}
          onChange={(e) => handleChange(e.target.value)}
          sx={{ width: 75 }}
          options={[
            { value: 5, name: 5 },
            { value: 4, name: 4 },
            { value: 3, name: 3 },
            { value: 2, name: 2 },
            { value: 1, name: 1 }
          ]}
        />
      </div>
    </>
  );
};

export const DateView = ({ field }) => (
  <DatePicker
    sx={{
      mt: 2,
      mb: 1,
      '&  .Mui-disabled input::placeholder': {
        opacity: 1
      }
    }}
    disabled
  />
);

export const FileUpload = ({ field, updateField }) => {
  const handleChange = (keyValue) => {
    updateField({ file: { ...field.file, ...keyValue } }, field.id);
  };

  return (
    <FileUploadWrapper>
      <Button
        startIcon={<UploadIcon />}
        color="secondary"
        variant="outlined"
        sx={{ mt: 2 }}
      >
        Upload
      </Button>

      <section>
        <div>
          <Select
            label="File number limit"
            value={field.file.numberLimit}
            onChange={(e) => handleChange({ numberLimit: e.target.value })}
            sx={{ width: 116, height: 32, mt: '12px' }}
            options={[
              { value: 1, name: 1 },
              { value: 3, name: 3 },
              { value: 5, name: 5 }
            ]}
          />
        </div>
        <div>
          <Select
            label="File type"
            value={field.file.type}
            onChange={(e) => handleChange({ type: e.target.value })}
            sx={{ width: 123, height: 32, mt: '12px' }}
            options={[
              { value: '.pdf', name: 'PDF' },
              { value: '.jpeg', name: 'JPEG' },
              { value: '.png', name: 'PNG' }
            ]}
          />
        </div>
        <div>
          <Select
            label="Single file size limit"
            value={field.file.singleFileSizeLimit}
            onChange={(e) => handleChange({ singleFileSizeLimit: e.target.value })}
            sx={{
              width: 80,
              height: 32,
              mt: '12px'
            }}
            options={[
              { value: 1, name: '5MB' },
              { value: 3, name: '10MB' },
              { value: 5, name: '15MB' }
            ]}
          />
        </div>
      </section>
    </FileUploadWrapper>
  );
};

const OptionsDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  '& > *': {
    display: 'block',
    margin: 'auto 0'
  },

  '& .MuiOutlinedInput-root': {
    height: 32,
    width: 418
  },

  '&+ button': {
    color: `${theme.palette.primary.main} !important`,
    textTransform: 'none',
    fontSize: pxToRem(14),
    display: 'block',
    paddingLeft: 0
  }
}));

const FileUploadWrapper = styled('div')(({ theme }) => ({
  '& > section': {
    display: 'flex',
    width: 364,
    justifyContent: 'space-between',
    paddingTop: '29px',

    '& > div': {
      marginRight: 56
    }
  }
}));
