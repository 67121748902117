import { Controller } from 'react-hook-form';
import Select from './Select';

export const ControlSelect = ({
  name,
  label,
  options,
  defaultValue,
  control,
  controllerProps = {},
  ...inputProps
}) => {
  return (
    <Controller
      control={control}
      name={name}
      {...controllerProps}
      render={({ field: { ref, ...rest } }) => (
        <Select
          label={label}
          defaultValue={defaultValue}
          options={options}
          inputRef={ref}
          {...rest}
          {...inputProps}
        />
      )}
    />
  );
};
