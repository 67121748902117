/* eslint-disable */
import { Drawer, Tabs, Tab, SvgIcon, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import Auth from 'utils/Auth';

import { ReactComponent as Overview } from 'assets/sideBarIcon/overview.svg';
import { ReactComponent as OverviewActive } from 'assets/sideBarIcon/overview_active.svg';
import { ReactComponent as Programs } from 'assets/sideBarIcon/program.svg';
import { ReactComponent as ProgramsActive } from 'assets/sideBarIcon/program_active.svg';
import { ReactComponent as People } from 'assets/sideBarIcon/people.svg';
import { ReactComponent as PeopleActive } from 'assets/sideBarIcon/people_active.svg';
import { ReactComponent as Activities } from 'assets/sideBarIcon/activities.svg';
import { ReactComponent as ActivitiesActive } from 'assets/sideBarIcon/activities_active.svg';
import { ReactComponent as Events } from 'assets/sideBarIcon/events.svg';
import { ReactComponent as EventsActive } from 'assets/sideBarIcon/events_active.svg';
import { ReactComponent as Settings } from 'assets/sideBarIcon/settings.svg';
import { ReactComponent as SettingsActive } from 'assets/sideBarIcon/settings_active.svg';
import { ReactComponent as Logout } from 'assets/sideBarIcon/logout.svg';

export const SideBar = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const getSelectedKey = (pathname = location.pathname) => {
    return pathname?.split('/')?.[1] || 'overview';
  };

  const handleLogout = () => {
    Auth.removeToken();
    navigate('/', { replace: true });
  };

  return (
    <Drawer variant="permanent" anchor="left" className={classes.drawerRoot}>
      <Tabs
        orientation="vertical"
        variant="fullWidth"
        value={getSelectedKey()}
        className={classes.tabsRoot}
      >
        {menuItems?.map((tab, tabIndex) => {
          return (
            <Tab
              className={classes.tabRoot}
              onClick={() => navigate(tab.path)}
              value={getSelectedKey(tab.path)}
              key={tab?.path || tabIndex}
              label={tab.label}
              icon={
                getSelectedKey(tab.path) === location.pathname.split('/')[1] ? (
                  <SvgIcon
                    color="error"
                    component={tab.activeIcon}
                    viewBox="0 0 16 16"
                    fontSize="inherit"
                  />
                ) : (
                  <SvgIcon
                    color="error"
                    component={tab.icon}
                    viewBox="0 0 16 16"
                    fontSize="inherit"
                  />
                )
              }
            />
          );
        })}
        <Tab
          className={classes.tabRoot}
          onClick={handleLogout}
          label="Logout"
          icon={<SvgIcon component={Logout} viewBox="0 0 16 16" fontSize="inherit" />}
        />
      </Tabs>
      <footer className="footer">
        <Typography>Powered by Prunedge</Typography>
      </footer>
    </Drawer>
  );
};

const useStyles = makeStyles((theme) => ({
  drawerRoot: {
    height: '100%',

    '& .MuiPaper-root': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      position: 'unset',
      height: '100%',
      borderRight: 'none'
    },

    '& .footer': {
      background: theme.palette.primary.lighterAlt,
      padding: '8px 24px',

      '& .MuiTypography-root': {
        fontSize: 12,
        fontWeight: 400,
        color: theme.palette.primary.main
      }
    }
  },

  tabsRoot: {
    paddingTop: 40,
    paddingBottom: 48,
    flexGrow: 1,

    '& .MuiTabs-flexContainer': {
      height: '100%'
    }
  },

  tabRoot: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minHeight: 36,
    maxHeight: 36,
    fontSize: 14,
    fontWeight: 400,
    padding: '8px 24px',

    '&:hover': {
      background: theme.palette.primary.lighterAlt
    },

    '&:nth-last-child(2)': {
      marginTop: 'auto'
    },

    '& .MuiSvgIcon-root': {
      marginRight: 10,
      fontSize: 16,
      color: 'transparent',
      marginBottom: 0
    }
  }
}));

const userMenuItems = [
  {
    label: 'Overview',
    // path: '/overview',
    path: '',
    icon: Overview,
    activeIcon: OverviewActive
  },
  {
    label: 'Programs',
    path: '/programs',
    icon: Programs,
    activeIcon: ProgramsActive
  },
  {
    label: 'Activities',
    path: '/activities',
    icon: Activities,
    activeIcon: ActivitiesActive
  },
  {
    label: 'Events',
    path: '/events',
    icon: Events,
    activeIcon: EventsActive
  },
  {
    label: 'People',
    path: '/people',
    icon: People,
    activeIcon: PeopleActive
  }
];

const defaultMenuItems = [
  {
    label: 'Settings',
    path: '/settings',
    icon: Settings,
    activeIcon: SettingsActive
  }
  // {
  //   label: 'Support',
  //   path: '/support',
  //   icon: Support
  // }
];

const menuItems = [...userMenuItems, ...defaultMenuItems];
