import { pxToRem } from '../utils/formatFont';

// ----------------------------------------------------------------------

function responsiveFontSizes({ sm, md, lg }) {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm)
    },
    '@media (min-width:960px)': {
      fontSize: pxToRem(md)
    },
    '@media (min-width:1280px)': {
      fontSize: pxToRem(lg)
    }
  };
}

const FONT_FAMILY = "'Open Sans', sans-serif"; // Google Font

const typography = {
  fontFamily: FONT_FAMILY,
  fontWeightRegular: 400,
  fontWeightMedium: 'bold',
  fontWeightBold: 'bold',
  h1: {
    fontWeight: 'bold',
    lineHeight: '61px',
    ...responsiveFontSizes({ sm: 46, md: 46, lg: 46 })
  },
  h2: {
    fontWeight: 'bold',
    lineHeight: '43px',
    ...responsiveFontSizes({ sm: 32, md: 32, lg: 32 })
  },
  h3: {
    fontWeight: 600,
    lineHeight: '36px',
    ...responsiveFontSizes({ sm: 26, md: 26, lg: 28 })
  },
  h4: {
    fontWeight: 'bold',
    lineHeight: '31px',
    ...responsiveFontSizes({ sm: 23, md: 23, lg: 23 })
  },
  h5: {
    fontWeight: 600,
    lineHeight: '28px',
    fontSize: pxToRem(20),
    ...responsiveFontSizes({ sm: 20, md: 20, lg: 20 })
  },
  h6: {
    fontWeight: 600,
    lineHeight: '24px',
    ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 })
  },
  subtitle1: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(16)
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: '20px',
    fontSize: pxToRem(14)
  },
  body1: {
    lineHeight: 1.5,
    fontSize: pxToRem(16)
  },
  body2: {
    lineHeight: '20px',
    fontSize: pxToRem(14)
  },
  caption: {
    lineHeight: '16px',
    fontSize: pxToRem(12)
  },
  overline: {
    fontWeight: 'bold',
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    letterSpacing: 1.2,
    textTransform: 'uppercase'
  },
  button: {
    fontWeight: 'bold',
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    textTransform: 'capitalize'
  }
};

export default typography;
