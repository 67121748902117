/* eslint-disable */
import { styled } from '@mui/material';
import componentTypes from './components';

const FormComponent = ({
  fields,
  onFill,
  filledFields,
  setErrors,
  triggerValidation,
  setSubmitLoader
}) => {
  return (
    <StyledFormFields>
      {fields?.map((field) => {
        const Component = componentTypes[field.type];
        return (
          <div key={field.id}>
            <Component
              key={field.key + field.label}
              field={field}
              onFill={onFill}
              existingValue={filledFields[field.key]?.value}
              setErrors={setErrors}
              triggerValidation={triggerValidation}
              setSubmitLoader={setSubmitLoader}
            />
          </div>
        );
      })}
    </StyledFormFields>
  );
};

export default FormComponent;

const StyledFormFields = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr',
  transition: '0.3s ease-in-out',
  gap: 29
});
