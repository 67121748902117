// Specify and export all paths from this file

// should this be called program path or all paths?
// program pathz is confusing, shouldnt we call this AllPaths?.
export const ProgramPaths = {
  OVERVIEW: '/overview',
  PROGRAM: '/program',
  PROGRAMS: '/programs',
  PROGRAM_BENEFICIARIES: '/programs/:programId/beneficiaries',
  PROGRAM_FACILITATORS: '/programs/:programId/facilitators',
  PROGRAM_DETAILS: '/programs/:programId',
  ACTIVITIES: '/activities',
  EVENTS: '/events',
  PEOPLE: '/people',
  FACILITATORS: '/people/facilitators',
  BENEFICIARIES: '/people/beneficiary',
  PROGRAM_MANAGERS: '/people/program-managers',
  PROGRAM_MANAGER: '/people/program-managers/:programManagerId',
  SETTINGS: '/settings',
  SUPPORT: '/support',
  FORMS: '/forms',
  PROFILE: '/profile',
  BENEFICIARY: '/beneficiary'
};

export const AuthPaths = {
  SIGNIN: '/signin',
  SIGNUP: '/signup',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password'
};

export const ActivitiesPaths = {
  ACTIVITIES: '/activities',
  ASSESSMENTS: '/activities/assessments',
  ASSESSMENT_DETAILS: '/activities/assessments/:id'
};

export const EventPaths = {
  EVENTS: '/events',
  EVENT_DETAILS: '/events/:id'
};
