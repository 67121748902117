import validator from 'validator';
import { fieldTypes } from '../FormBuilder/constants';

const isRequired = (value) => {
  return !value ? 'This field is required' : '';
};

const requiredOnly = (value, field) => {
  let errorMessage = '';
  if (field.required) errorMessage = isRequired(value);
  return errorMessage;
};

const emailValidation = (value, field) => {
  if (requiredOnly(value, field)) return requiredOnly(value, field);
  if (value && !validator.isEmail(value)) return 'Invalid email';
};

const phoneNumberValidation = (value, field) => {
  if (requiredOnly(value, field)) return requiredOnly(value, field);
  if (value && !validator.isMobilePhone(value)) return 'Invalid phone number';
};

const linkValidation = (value, field) => {
  if (requiredOnly(value, field)) return requiredOnly(value, field);
  if (value && !validator.isURL(value)) return 'Invalid url';
};

const fileUpload = (value, field) => {
  let message = '';
  message = requiredOnly(value, field);
  const { numberLimit } = field.file;
  if (value && value.length > numberLimit) {
    message = `The maximum number of uploads is ${numberLimit} ${
      numberLimit > 1 ? 'files' : 'file'
    }`;
  }

  return message;
};

const validations = {
  [fieldTypes.SHORT_ANSWER]: requiredOnly,
  [fieldTypes.LONG_ANSWER]: requiredOnly,
  [fieldTypes.SINGLE_ANSWER]: requiredOnly,
  [fieldTypes.MULTIPLE_ANSWER]: requiredOnly,
  [fieldTypes.RATING]: requiredOnly,
  [fieldTypes.DATE]: requiredOnly,
  [fieldTypes.FILE_UPLOAD]: fileUpload,
  [fieldTypes.SELECT]: requiredOnly,
  [fieldTypes.LINKS]: linkValidation,
  [fieldTypes.PHONE_NUMBER]: phoneNumberValidation,
  [fieldTypes.EMAIL]: emailValidation
};

export { validations };
