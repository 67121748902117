import clsx from 'clsx';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

import InputLabel from './InputLabel';

// See https://mui.com/api/input/ for the <Input/> props
const InputField = ({ label, className, error, ...props }) => {
  const classes = useStyles();

  return (
    <>
      {label && <InputLabel>{label}</InputLabel>}
      <TextField
        className={clsx(classes.root, className)}
        variant="outlined"
        error={!!error}
        {...props}
      />
    </>
  );
};

InputField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string
};

InputField.defaultProps = {
  label: '',
  className: ''
};

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#C8C6C4'
    },
    '& .MuiInputBase-root': {
      border: 'none',
      borderRadius: 4,
      fontWeight: 400,
      borderImage: 'none',
      padding: 0,
      '& input': {
        padding: 10,
        fontSize: `${14 / 16}rem`
      }
    },
    '& .MuiOutlinedInput-root.Mui-disabled': {
      background: theme.palette.grey.dashedBorder
    }
  }
}));

export default InputField;
