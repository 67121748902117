import { Controller } from 'react-hook-form';
import Switch from './Switch';

export const ControlSwitch = ({ control, name, label, ...restProps }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref, ...rest } }) => (
        <Switch label={label} inputRef={ref} {...restProps} {...rest} />
      )}
    />
  );
};
