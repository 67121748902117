import { useReducer, useEffect } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  FormHelperText
} from '@mui/material';
import { validations } from '../helper.formRenderer';

const SingleAnswer = ({ field, onFill, triggerValidation, existingValue = null }) => {
  const validator = validations[field.type];
  const initialState = {
    value: existingValue,
    errorMessage: ''
  };
  const reducer = (state, action) => {
    switch (action.type) {
      case 'setValue':
        return { ...state, value: action.payload };

      case 'validate':
        return { ...state, errorMessage: validator(state.value, field) };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const { value, errorMessage } = state;

  useEffect(() => {
    if (triggerValidation) dispatch({ type: 'validate' });
  }, [triggerValidation]);

  const onChange = (e) => {
    dispatch({ type: 'setValue', payload: e.target.value });
    dispatch({ type: 'validate' });
    onFill({ [field.key]: { value: e.target.value, id: field.id } });
  };

  return (
    <FormControl>
      <FormLabel error={!!errorMessage}>{field.label}</FormLabel>
      <RadioGroup value={value} onChange={onChange}>
        {field.options.map((option) => (
          <FormControlLabel
            key={option.id}
            value={option.value}
            control={<Radio />}
            label={option.label || option.value || ''}
          />
        ))}
      </RadioGroup>
      <FormHelperText error={!!errorMessage}>{errorMessage}</FormHelperText>
    </FormControl>
  );
};

export default SingleAnswer;
