import { styled } from '@mui/material';
import { Header } from './Header';

export const ProgramLayout = ({ children }) => {
  return (
    <Main>
      <Header />
      <article className="appContent">{children}</article>
    </Main>
  );
};

const Main = styled('main')(() => ({
  display: 'grid',
  gridTemplateColumns: 'auto',
  gridTemplateRows: '48px auto',
  gridTemplateAreas: `
  'header'
  'content'`,
  height: '100vh',

  '& .appContent': {
    background: '#FFFFFF',
    gridArea: 'content',
    height: '100%',
    overflowY: 'auto'
  }
}));
