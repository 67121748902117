import { Chip, styled } from '@mui/material';
import PropTypes from 'prop-types';

export const TableChip = ({ text, ...rest }) => {
  return (
    <StyledChip
      label={text}
      {...rest}
      sx={{
        '&.MuiChip-colorDefault': {
          backgroundColor: text === 'PUBLISHED' ? '#D4F7DC' : '#D2D0CE'
        }
      }}
    />
  );
};

TableChip.propTypes = {
  text: PropTypes.string
};

TableChip.defaultProps = {
  text: ''
};

const StyledChip = styled(Chip)({
  fontSize: 12,
  borderRadius: 8,
  height: 'unset',
  lineHeight: 1.5,

  '& .MuiSvgIcon-root': {
    fontSize: 16,
    marginRight: 0,
    marginLeft: 8,
    color: 'transparent'
  },

  '&.MuiChip-colorInfo': {
    backgroundColor: '#DEECF9',

    '& .MuiChip-label': {
      color: '#2B88D8'
    }
  },

  '&.MuiChip-colorSuccess': {
    backgroundColor: '#5FD25533',

    '& .MuiChip-label': {
      color: '#107C10'
    }
  },

  '&.MuiChip-colorWarning': {
    backgroundColor: 'rgba(255, 200, 10, 0.2)',

    '& .MuiChip-label': {
      color: '#797775'
    }
  },

  '&.MuiChip-colorDefault': {
    // backgroundColor: '#D2D0CE',

    '& .MuiChip-label': {
      color: '#605E5C'
    }
  },

  '& .MuiChip-label': {
    padding: '4px 8px'
  }
});
