import { useReducer, useEffect, Fragment } from 'react';
import { FormHelperText } from '@mui/material';
import { validations } from '../helper.formRenderer';
import InputField from '../../InputField';
import InputLabel from '../../InputLabel';

const Links = ({ field, onFill, triggerValidation, existingValue = '' }) => {
  const getInitialValues = () => {
    const existing = existingValue.split('; ');
    let options = {};
    field.options.forEach((option, index) => {
      options = { ...options, [option.key]: existing[index] || '' };
    });
    return options;
  };

  useEffect(() => {
    if (triggerValidation) dispatch({ type: 'validate' });
  }, [triggerValidation]);

  const combineFields = (f) =>
    Object.values(f)
      .filter((v) => v)
      .join('; ');

  const validator = validations[field.type];

  const initialState = {
    fields: getInitialValues(),
    errorMessage: ''
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'inputChange':
        return {
          ...state,
          fields: { ...state.fields, [action.payload.name]: action.payload.value }
        };

      case 'validate':
        return { ...state, errorMessage: validator(combineFields(state.fields), field) };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const onBlur = () => {
    onFill({
      [field.key]: {
        id: field.id,
        value: JSON.stringify(state.fields)
      }
    });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    dispatch({ type: 'inputChange', payload: { name, value } });
    dispatch({ type: 'validate' });
  };

  return (
    <div>
      <InputLabel sx={{ mb: 1 }} error={!!state.errorMessage}>
        {field.label}
      </InputLabel>
      {field.options.map((option) => (
        <Fragment key={option.key}>
          <InputLabel style={{ fontSize: '0.7rem' }}>
            {option.label || option.key}
          </InputLabel>
          <InputField
            fullWidth
            error={!!state.errorMessage}
            sx={{ mb: 1 }}
            value={state.fields[option.key]}
            name={option.key}
            onChange={onChange}
            onBlur={onBlur}
            onInput={onBlur}
          />
        </Fragment>
      ))}
      <FormHelperText error>{state.errorMessage}</FormHelperText>
    </div>
  );
};

export default Links;
