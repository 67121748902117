import { useState } from 'react';
import { ReactComponent as MoreActionIcon } from 'assets/more-actions-icon.svg';
import { IconButton, SvgIcon, Menu, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

export const GridMoreActions = ({ menuItems }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (action) => {
    if (action && typeof action === 'string') navigate(action);
    if (action && typeof action === 'function') action();
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton className={classes.actionBtn} onClick={handleClick}>
        <SvgIcon component={MoreActionIcon} viewBox="0 0 2 14" />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 70
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        PopoverClasses={{
          root: classes.menuContent
        }}
      >
        {menuItems.map((item) => (
          <MenuItem key={item.label} onClick={() => handleClose(item.action)}>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

GridMoreActions.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      action: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
    })
  ).isRequired
};

const useStyles = makeStyles((theme) => ({
  actionBtn: {
    '& .MuiSvgIcon-root': {
      fontSize: 15
    }
  },

  menuContent: {
    '& .MuiPaper-root': {
      borderRadius: 0
    },

    '& .MuiList-root': {
      padding: 0,

      '& .MuiMenuItem-root': {
        fontSize: 14,
        fontWeight: 400,
        cursor: 'pointer',
        color: theme.palette.text.tertiary
      }
    }
  }
}));
