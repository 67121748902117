import { useEffect, useReducer } from 'react';
import { validations } from '../helper.formRenderer';
import TextArea from '../../TextArea';

const LongAnswer = ({ field, onFill, triggerValidation, existingValue = '' }) => {
  const validator = validations[field.type];

  const initialState = {
    value: existingValue,
    errorMessage: ''
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'setValue':
        return { value: action.payload, errorMessage: validator(action.payload, field) };
      case 'validate':
        return { ...state, errorMessage: validator(state.value, field) };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const { value, errorMessage } = state;

  useEffect(() => {
    if (triggerValidation) dispatch({ type: 'validate' });
  }, [triggerValidation]);

  const handleChange = (e) => {
    dispatch({ type: 'setValue', payload: e.target.value });
  };

  const onBlur = () => {
    dispatch({ type: 'validate' });
    onFill({ [field.key]: { value, id: field.id } });
  };

  return (
    <TextArea
      label={field.label}
      maxRows={5}
      minRows={5}
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
      onInput={onBlur}
      error={!!errorMessage}
      helperText={errorMessage}
    />
  );
};

export default LongAnswer;
