import { Card, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

export const FormActionCard = ({ primaryBtnProps, secondaryBtnProps, primaryOnly }) => {
  const classes = useStyles();

  return (
    <Card className={classes.actionBox}>
      {primaryOnly ? null : (
        <Button
          disabled={secondaryBtnProps.disabled}
          color="secondary"
          variant="outlined"
          onClick={secondaryBtnProps.onClick}
        >
          {secondaryBtnProps.label}
        </Button>
      )}
      <LoadingButton
        disabled={primaryBtnProps.disabled}
        loading={primaryBtnProps.loading}
        color="primary"
        variant="contained"
        type="submit"
        onClick={primaryBtnProps.onClick}
      >
        {primaryBtnProps.label}
      </LoadingButton>
    </Card>
  );
};

FormActionCard.prototype = {
  primaryBtnProps: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    disabled: PropTypes.bool
  }),
  secondaryBtnProps: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  })
};

FormActionCard.defaultProps = {
  primaryBtnProps: {
    label: 'Submit',
    onClick: () => {},
    loading: false,
    disabled: false
  },
  secondaryBtnProps: {
    label: 'Cancel',
    onClick: () => {},
    disabled: false
  }
};

const useStyles = makeStyles((theme) => ({
  actionBox: {
    marginTop: 24,
    width: '100%',
    maxWidth: 600,
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 16,
    maxHeight: 64,
    marginLeft: 'auto',
    marginRight: 'auto',

    '& .MuiButton-root:first-of-type': {
      marginRight: '16px'
    },

    '& .MuiButton-root': {
      padding: '4px 20px',
      borderRadius: 2
    },

    '& .Mui-disabled': {
      background: theme.palette.grey.light,
      color: '#A19F9D'
    }
  }
}));
