/* eslint-disable */
import { Button, styled } from '@mui/material';
import { v4 as uuid } from 'uuid';
import { ReactComponent as RatingStar } from 'assets/RatingStar.svg';
import { ReactComponent as UploadIcon } from 'assets/UploadBottom.svg';
import InputField from '../InputField';
import TextArea from '../TextArea';
import InputLabel from '../InputLabel';
import Radio from '../Radio';
import Checkbox from '../Checkbox';
import DatePicker from '../DatePicker';

export const ShortAnswer = ({ field }) => (
  <InputField fullWidth disabled value={field.placeholder || ''} label={field.label} />
);

export const LongAnswer = ({ field }) => (
  <TextArea
    maxRows={5}
    minRows={4}
    label={field.label}
    placeholder={field.placeholder || ''}
  />
);
export const SingleAnswer = ({ field }) => (
  <>
    <InputLabel>{field.label}</InputLabel>
    <SingleAnswerOptions>
      {field.options.map((option) => (
        <span key={uuid()}>
          <Radio label={option.label} checked={false} />
        </span>
      ))}
    </SingleAnswerOptions>
  </>
);

export const MultipleAnswers = ({ field }) => (
  <>
    <InputLabel>{field.label}</InputLabel>
    {field.options.map((option) => (
      <div key={uuid()}>
        <Checkbox label={option.label} />
      </div>
    ))}
  </>
);

export const Rating = ({ field }) => {
  const ratings = new Array(field.ratingLevel).fill(
    <RatingStar style={{ marginRight: 10 }} />
  );
  return (
    <>
      <InputLabel style={{ marginBottom: 16 }}>{field.label}</InputLabel>
      {ratings}
    </>
  );
};

export const DateView = ({ field }) => <DatePicker label={field.label} />;

export const FileUpload = ({ field }) => (
  <>
    <InputLabel>{field.label}</InputLabel>
    <Button
      startIcon={<UploadIcon />}
      color="secondary"
      variant="outlined"
      sx={{ mt: 2 }}
    >
      Upload
    </Button>
  </>
);

// TODO: delete later
export const Placeholder = () => {
  return (
    <div>
      <p>Delete later</p>
    </div>
  );
};

const SingleAnswerOptions = styled('div')(({ theme }) => ({
  marginTop: 8,
  marginBottom: 0,
  padding: 0,
  '& > span': {
    display: 'block',
    marginTop: -5
  }
}));
