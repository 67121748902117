import { Controller } from 'react-hook-form';
import { makeStyles } from '@mui/styles';
import InputField from './InputField';

export const ControlInputField = ({
  name,
  label,
  control,
  controllerprops,
  ...InputProps
}) => {
  const classes = useStyles();

  return (
    <Controller
      name={name}
      control={control}
      {...controllerprops}
      render={({ field: { ref, ...rest } }) => (
        <InputField
          className={classes.formControl}
          fullWidth
          label={label}
          inputRef={ref}
          {...InputProps}
          {...rest}
        />
      )}
    />
  );
};

const useStyles = makeStyles({
  formControl: {
    '& .MuiFormHelperText-root': {
      marginLeft: 0
    }
  }
});
