import { useReducer, useEffect } from 'react';
import { validations } from '../helper.formRenderer';
import InputField from '../../InputField';

const ShortAnswer = ({ field, onFill, triggerValidation, existingValue = '' }) => {
  const validator = validations[field.type];

  const initialState = {
    value: existingValue,
    errorMessage: ''
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'setValue':
        return {
          value: action.payload,
          ...(state.errorMessage
            ? { errorMessage: validator(action.payload, field) }
            : {})
        };
      case 'validate':
        return { ...state, errorMessage: validator(state.value, field) };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const { value, errorMessage } = state;

  useEffect(() => {
    if (triggerValidation) dispatch({ type: 'validate' });
  }, [triggerValidation]);

  const handleChange = (e) => {
    dispatch({ type: 'setValue', payload: e.target.value });
  };

  const onBlur = () => {
    dispatch({ type: 'validate' });
    onFill({ [field.key]: { value, id: field.id } });
  };

  return (
    <InputField
      label={field.label}
      fullWidth
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
      onInput={onBlur}
      error={!!errorMessage}
      helperText={errorMessage}
    />
  );
};

export default ShortAnswer;
