// LOCAL CACHE DB CREATED FROM LOCAL STORAG TO MIMICK AN ACTUAL DATABASE
// PLEASE SAVE YOUR STORE DATA AS OBJECTS.
// ALL STORES ARE ASSUMED TO BE OBJECTS WITH KEY INDEX WHICH ARE ARRAYS.

import { createContext, useEffect } from 'react';

export const PersistenceContext = createContext({});

const db = window.localStorage;
const dbName = 'NAKISE_WEB_DB';

export const PersistenceLayoutProvider = ({ children }) => {
  useEffect(() => {
    const isDbFound = db.getItem(dbName);
    if (!isDbFound) {
      db.setItem(dbName, JSON.stringify({}));
    }
  }, []);

  const handleCheckItemType = (item) => {
    try {
      if (item === null) throw new Error('item cannot be of null value');
      if (Array.isArray(item)) return 'array';
      if (typeof item === 'object') return 'object';
      return 'string';
    } catch (e) {
      throw new Error('An error occurred');
    }
  };

  const handleAddItem = (storeName, item, keyIndex) => {
    try {
      const nakiseDb = JSON.parse(db.getItem(dbName));
      const isFound = nakiseDb[storeName][keyIndex];
      const itemType = handleCheckItemType(item);
      let currentData;
      if (!isFound) {
        switch (itemType) {
          case 'object':
            if (Object.keys(item).length <= 0)
              throw new Error('Object item must have key:value pairs');
            nakiseDb[storeName] = { [keyIndex]: [{ ...item }] };
            break;
          case 'string':
            if (!item) throw new Error('Item cannot be of empty string');
            nakiseDb[storeName] = { [keyIndex]: [item] };
            break;
          default:
            if (item.length <= 0) throw new Error('Cannot save an empty array');
            nakiseDb[storeName] = { [keyIndex]: [...item] };
            break;
        }
        db.setItem(dbName, JSON.stringify(nakiseDb));
      } else {
        //
        currentData = nakiseDb[storeName][keyIndex];
        switch (itemType) {
          case 'object':
            if (Object.keys(item).length <= 0)
              throw new Error('Object item must have key:value pairs');
            currentData.push({ ...item });
            break;
          case 'string':
            if (!item) throw new Error('Item cannot be of empty string');
            currentData.push(item);
            break;
          default:
            if (item.length <= 0) throw new Error('Cannot save an empty array');
            currentData.push([...item]);
            break;
        }
        nakiseDb[storeName][keyIndex] = [...currentData];
        db.setItem(dbName, JSON.stringify(nakiseDb));
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleGetAllStoreItems = (storeName, keyIndex) => {
    try {
      const nakiseDb = JSON.parse(db.getItem(dbName));
      const isFound = nakiseDb[storeName];
      if (isFound) {
        const data = nakiseDb[storeName][keyIndex] ?? [];
        return data;
      }
      throw new Error('store not found');
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  const editDataById = (storeName, newData, id, searchKey, keyIndex) => {
    try {
      const nakiseDb = JSON.parse(db.getItem(dbName));
      const items = nakiseDb[storeName][keyIndex];
      const findIndex = items?.findIndex((item) => item[searchKey] === id);
      if (findIndex > -1) {
        items[findIndex] = newData;
        nakiseDb[storeName][keyIndex] = items;
        db.setItem(dbName, JSON.stringify(nakiseDb));
      } else {
        return false;
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  const clearAllDataInStoreById = (storeName, keyIndex) => {
    try {
      const nakiseDb = JSON.parse(db.getItem(dbName));
      // find store
      const store = nakiseDb[storeName];
      if (Object.keys(store).length > 0) {
        nakiseDb[storeName][keyIndex] = [];
      }
      db.setItem(dbName, JSON.stringify(nakiseDb));
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  const deleteDataById = (storeName, id, searchKey, keyIndex) => {
    try {
      const nakiseDb = JSON.parse(db.getItem(dbName));
      const newData = nakiseDb[storeName][keyIndex].filter(
        (data) => data[searchKey] !== id
      );
      nakiseDb[storeName][keyIndex] = newData;
      db.setItem(dbName, JSON.stringify(nakiseDb));
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  return (
    <PersistenceContext.Provider
      value={{
        addData: handleAddItem,
        getAllDataByStoreName: handleGetAllStoreItems,
        editById: editDataById,
        clearStoreDataById: clearAllDataInStoreById,
        deleteById: deleteDataById
      }}
    >
      {children}
    </PersistenceContext.Provider>
  );
};

export const createNewStore = (storeName) => {
  try {
    const nakiseDb = JSON.parse(db.getItem(dbName));
    const isFound = nakiseDb[storeName];
    if (!isFound) {
      nakiseDb[storeName] = {};
      db.setItem(dbName, JSON.stringify(nakiseDb));
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};
