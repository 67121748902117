/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Box,
  Grow,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const ProgramStarterCard = ({ title, desc, continueFn, img, disabled, appear }) => {
  const classes = useStyles();

  return (
    <Grow
      in={appear}
      style={{ transformOrigin: '0 0 0' }}
      {...(appear && { timeout: 1000 })}
    >
      <Card className={classes.cardRoot}>
        <CardMedia component="img" height={146} image={img} alt="" />
        <CardContent>
          <Typography variant="h6" className="cardTitle">
            {title}
          </Typography>
          <Typography variant="body2" className="cardDesc">
            {desc}
          </Typography>
        </CardContent>
        <CardActions>
          <Button disabled={disabled} onClick={continueFn} size="small" color="primary">
            Continue
          </Button>
        </CardActions>
        {disabled && (
          <Box
            py={1}
            px={5}
            backgroundColor="primary.lighter"
            sx={{
              transform: 'rotate(-36deg)',
              position: 'absolute',
              bottom: '15px',
              right: '-25px'
            }}
          >
            <Typography fontSize="12px" color="#fff">
              Coming Soon
            </Typography>
          </Box>
        )}
      </Card>
    </Grow>
  );
};

ProgramStarterCard.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  continueFn: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  img: PropTypes.node.isRequired,
  appear: PropTypes.bool.isRequired
};

ProgramStarterCard.defaultProps = {
  disabled: false
};

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    maxWidth: 288,
    position: 'relative',
    background: 'linear-gradient(0deg, rgba(255,255,255,1) 9%, rgba(239,246,252,1) 42%)',

    '& .MuiCardMedia-root': {
      width: '70%',
      height: 'auto',
      margin: '0 auto',
      paddingTop: 16,
      paddingBottom: 10
    },

    '& .MuiCardContent-root': {
      backgroundColor: theme.palette.common.white,
      padding: '16px 24px',

      '& .MuiTypography-root.cardTitle': {
        fontWeight: 600,
        color: theme.palette.text.tertiary,
        marginBottom: 10
      },

      '& .MuiTypography-root.cardDesc': {
        color: theme.palette.text.secondary,
        fontWeight: 400
      }
    },

    '& .MuiCardActions-root': {
      backgroundColor: theme.palette.common.white,
      padding: '2px 24px 8px',

      '& .MuiButton-root': {
        '&:hover': {
          backgroundColor: 'transparent'
        }
      }
    }
  }
}));

export default ProgramStarterCard;
