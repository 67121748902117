import { Chip, styled } from '@mui/material';
import PropTypes from 'prop-types';
import { ReactComponent as CancelIcon } from 'assets/ChipCancelIcon.svg';

const BoxChip = ({ label, ...rest }) => {
  // eslint-disable-next-line
  return <StyledChip label={label} deleteIcon={<CancelIcon />} {...rest} />;
};

Chip.propTypes = {
  label: PropTypes.string.isRequired
};

export default BoxChip;

const StyledChip = styled(Chip)(({ theme }) => ({
  background: theme.palette.grey.light,
  borderRadius: 0,
  '& .MuiChip-deleteIcon': {
    marginLeft: 8,
    marginRight: 8
  }
}));
