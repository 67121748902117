import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Toaster } from 'react-hot-toast';
import { makeStyles } from '@mui/styles';
import { RoutesWrapper } from 'routes';
import { createNewStore, PersistenceLayoutProvider } from 'contexts/persistenceContext';
import { useEffect } from 'react';

const reactQueryConfig = {
  refetchOnWindowFocus: false,
  retry: false
};

const defaultOptions = {
  queries: reactQueryConfig,
  mutations: reactQueryConfig
};

const queryClient = new QueryClient({
  defaultOptions
});

const App = () => {
  const classes = useStyles();
  const toastOptions = {
    position: 'top-right',
    duration: 10000,
    className: classes.toast
  };

  useEffect(() => {
    createNewStore('assessmentQuestionsPreSave');
  }, []);

  return (
    <PersistenceLayoutProvider>
      <QueryClientProvider client={queryClient}>
        <RoutesWrapper />
        <Toaster toastOptions={toastOptions} />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </PersistenceLayoutProvider>
  );
};

const useStyles = makeStyles((theme) => ({
  toast: {
    border: 'none',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
    fontFamily: "'Open Sans', sans-serif",
    ...theme.typography.body1
  }
}));

export default App;
