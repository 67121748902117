import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import CloseIcon from 'assets/icons/close.png';

export default function Modal({
  content,
  open,
  onClose,
  title,
  okBtnText,
  okBtnAction,
  okBtnProps
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      PaperProps={{ sx: { borderRadius: 0 } }}
    >
      <DialogTitle
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Typography variant="h6">{title}</Typography>
        {onClose && (
          <IconButton aria-label="close" onClick={onClose} size="large">
            <Box component="img" src={CloseIcon} alt="Close dialog" />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent sx={{ pb: 0, overflowY: 'unset' }}>
        <Typography variant="body2">{content}</Typography>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <LoadingButton
          type="submit"
          variant="contained"
          onClick={okBtnAction}
          {...okBtnProps}
        >
          {okBtnText}
        </LoadingButton>
        <Button type="button" variant="outlined" color="inherit" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
